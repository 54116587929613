import React from "react";

const AudioIcon = ({ className, style, Time, id, play }) => {
  console.log(id, play, Time);
  return (
    <>
      <svg
        style={style}
        className={className}
        viewBox="0 0 52 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26 51.5C31.0434 51.5 35.9736 50.0044 40.1671 47.2024C44.3605 44.4005 47.6289 40.4179 49.5589 35.7584C51.4889 31.0989 51.9939 25.9717 51.01 21.0252C50.0261 16.0787 47.5974 11.535 44.0312 7.96874C40.465 4.4025 35.9213 1.97391 30.9748 0.989983C26.0283 0.00606 20.9011 0.511059 16.2416 2.44109C11.5821 4.37113 7.59952 7.63949 4.79755 11.8329C1.99557 16.0264 0.5 20.9566 0.5 26C0.5 32.763 3.18657 39.2491 7.96875 44.0312C12.7509 48.8134 19.237 51.5 26 51.5Z"
          fill="#F17922"
        />
        <path
          d="M25.9091 18.0006L18.7291 23.3506H14.7891C14.4788 23.3506 14.1813 23.4739 13.9619 23.6933C13.7424 23.9127 13.6191 24.2103 13.6191 24.5206V28.8806C13.6191 29.1909 13.7424 29.4885 13.9619 29.708C14.1813 29.9274 14.4788 30.0506 14.7891 30.0506H18.7291L25.8992 35.4806C25.9896 35.5559 26.1 35.6035 26.2169 35.6176C26.3338 35.6316 26.4522 35.6115 26.5579 35.5596C26.6636 35.5078 26.7521 35.4265 26.8127 35.3256C26.8732 35.2246 26.9033 35.1083 26.8992 34.9906V18.4906C26.9033 18.373 26.8732 18.2566 26.8127 18.1557C26.7521 18.0547 26.6636 17.9734 26.5579 17.9216C26.4522 17.8698 26.3338 17.8497 26.2169 17.8637C26.1 17.8777 25.9896 17.9253 25.8992 18.0006H25.9091Z"
          fill="white"
        />
        <path
          d="M36.9599 21.0001C36.1762 19.4461 35.0638 18.0812 33.6999 17.0001C33.631 16.9448 33.5513 16.9044 33.466 16.8814C33.3807 16.8584 33.2916 16.8534 33.2042 16.8665C33.1169 16.8797 33.0332 16.9107 32.9585 16.9578C32.8837 17.0049 32.8195 17.067 32.7699 17.1401C32.6751 17.2774 32.6357 17.4455 32.6598 17.6107C32.6839 17.7758 32.7698 17.9257 32.8999 18.0301C34.2032 19.0745 35.2547 20.3987 35.9766 21.9047C36.6984 23.4108 37.0721 25.0599 37.0699 26.73C37.0485 28.4121 36.6297 30.0653 35.8476 31.5547C35.0656 33.0441 33.9425 34.3274 32.5699 35.3C32.4382 35.4031 32.3504 35.5523 32.3244 35.7175C32.2984 35.8827 32.3361 36.0516 32.4299 36.1901C32.4789 36.2635 32.5423 36.3262 32.6164 36.3744C32.6904 36.4225 32.7735 36.4551 32.8605 36.47C32.9475 36.485 33.0367 36.4821 33.1225 36.4615C33.2084 36.4409 33.2892 36.4029 33.3599 36.35C34.8932 35.22 36.154 33.761 37.0499 32.0801C37.9186 30.4302 38.3749 28.5946 38.3799 26.73C38.3725 24.7243 37.8857 22.7494 36.9599 20.97V21.0001Z"
          fill="white"
        />
        <path
          d="M33.1199 23.5203C32.6166 22.4284 31.903 21.4463 31.02 20.6303C30.9547 20.5604 30.8747 20.5057 30.7858 20.4702C30.697 20.4347 30.6013 20.4193 30.5058 20.425C30.4103 20.4307 30.3172 20.4574 30.2332 20.5033C30.1492 20.5492 30.0764 20.613 30.02 20.6903C29.9068 20.8108 29.8438 20.97 29.8438 21.1353C29.8438 21.3007 29.9068 21.4598 30.02 21.5803C30.8117 22.2826 31.4403 23.1495 31.8618 24.1203C32.2833 25.0911 32.4875 26.1423 32.46 27.2003C32.4169 28.0909 32.184 28.962 31.777 29.7553C31.37 30.5486 30.7982 31.2459 30.0999 31.8003C29.9732 31.9026 29.8895 32.0488 29.8654 32.21C29.8413 32.3711 29.8786 32.5354 29.9699 32.6703C30.0196 32.746 30.0847 32.8104 30.1608 32.8595C30.2369 32.9086 30.3223 32.9413 30.4117 32.9554C30.5012 32.9695 30.5926 32.9648 30.6801 32.9415C30.7676 32.9183 30.8493 32.877 30.9199 32.8203C31.7651 32.1507 32.4576 31.3083 32.9508 30.3494C33.444 29.3905 33.7267 28.3373 33.7799 27.2603C33.8344 25.965 33.5772 24.6756 33.0299 23.5003L33.1199 23.5203Z"
          fill="white"
        />
        <path
          d="M28.2197 24.3706C28.1239 24.4691 28.0656 24.5979 28.0546 24.7348C28.0437 24.8717 28.0808 25.0082 28.1597 25.1206C28.4822 25.5881 28.6549 26.1427 28.6549 26.7106C28.6549 27.2786 28.4822 27.8331 28.1597 28.3006C28.0815 28.4115 28.0448 28.5463 28.0558 28.6815C28.0667 28.8167 28.1247 28.9437 28.2197 29.0406C28.277 29.1052 28.348 29.1561 28.4275 29.1898C28.507 29.2234 28.593 29.2389 28.6792 29.2351C28.7654 29.2312 28.8498 29.2082 28.926 29.1676C29.0022 29.1271 29.0683 29.07 29.1197 29.0006C29.588 28.3363 29.8393 27.5434 29.8393 26.7306C29.8393 25.9178 29.588 25.125 29.1197 24.4606C29.0712 24.392 29.0085 24.3346 28.9357 24.2925C28.8629 24.2505 28.7819 24.2248 28.6982 24.2172C28.6145 24.2096 28.5301 24.2203 28.451 24.2485C28.3718 24.2767 28.2997 24.3218 28.2397 24.3806L28.2197 24.3706Z"
          fill="white"
        />
        {id === 0 && (
          <>
            <path
              d="M6.55412 22.5477C7.25635 22.611 7.77615 22.8598 8.11352 23.2942C8.45107 23.7266 8.58583 24.3202 8.51781 25.075L8.40893 26.2829L4.14302 25.8985L4.26373 24.5592C4.32649 23.8628 4.55906 23.3386 4.96142 22.9865C5.36378 22.6345 5.89468 22.4882 6.55412 22.5477ZM6.49278 23.4893C5.57658 23.4067 5.082 23.7701 5.00907 24.5793L4.96568 25.0607L7.74348 25.3111L7.77846 24.923C7.857 24.0515 7.42844 23.5736 6.49278 23.4893Z"
              fill="white"
            />
            <path
              d="M7.56286 20.8085C7.87349 20.9014 8.12344 20.9211 8.31271 20.8676C8.50255 20.8122 8.63188 20.6695 8.70071 20.4393C8.76899 20.211 8.73936 20.0238 8.61184 19.8776C8.483 19.729 8.26234 19.608 7.94984 19.5145C7.63921 19.4216 7.39113 19.4025 7.2056 19.4571C7.02007 19.5117 6.89289 19.654 6.82406 19.8842C6.75578 20.1125 6.78419 20.3004 6.90929 20.4479C7.03251 20.5948 7.25037 20.715 7.56286 20.8085ZM8.21174 18.6388C8.72259 18.7916 9.0818 19.0458 9.28939 19.4014C9.49697 19.757 9.52886 20.1753 9.38503 20.6562C9.29494 20.9575 9.14998 21.2036 8.95016 21.3946C8.74847 21.585 8.50565 21.7051 8.22171 21.7547C7.93778 21.8043 7.63114 21.7799 7.3018 21.6814C6.78908 21.5281 6.43052 21.2751 6.22612 20.9224C6.02172 20.5698 5.99199 20.1511 6.13693 19.6665C6.22703 19.3652 6.37198 19.1191 6.5718 18.9281C6.77162 18.7371 7.01257 18.6165 7.29463 18.5663C7.5767 18.5161 7.8824 18.5403 8.21174 18.6388Z"
              fill="white"
            />
            <path
              d="M10.881 17.3183C10.4007 18.2176 9.66698 18.4037 8.67982 17.8764C8.18882 17.6142 7.87944 17.2917 7.75168 16.9091C7.62219 16.5255 7.67937 16.1055 7.9232 15.6489C8.10171 15.3147 8.32728 15.0499 8.59991 14.8545L9.08557 15.4128C8.96936 15.5102 8.86722 15.604 8.77916 15.6942C8.68938 15.7836 8.61597 15.8816 8.55892 15.9884C8.33993 16.3985 8.52158 16.759 9.10389 17.07C9.66897 17.3718 10.061 17.3177 10.28 16.9077C10.361 16.756 10.4161 16.6051 10.4455 16.4547C10.4732 16.3034 10.4785 16.1401 10.4613 15.9649L11.1358 16.3251C11.1487 16.4959 11.1357 16.655 11.0968 16.8025C11.0588 16.9483 10.9868 17.1202 10.881 17.3183Z"
              fill="white"
            />
            <path
              d="M12.1138 14.2967C12.2136 14.1766 12.3072 14.0105 12.3945 13.7986L12.9059 14.2237C12.8402 14.4586 12.7037 14.7007 12.4965 14.95C12.268 15.2249 12.0322 15.368 11.7892 15.3793C11.5459 15.3879 11.2853 15.2767 11.0074 15.0457L9.79301 14.0364L9.51961 14.3653L9.22896 14.1237L9.31376 13.5542L8.94684 12.914L9.312 12.4746L9.84823 12.9203L10.4344 12.2151L10.9503 12.644L10.3642 13.3492L11.5786 14.3585C11.6762 14.4397 11.7708 14.4726 11.8623 14.4572C11.955 14.4403 12.0389 14.3868 12.1138 14.2967Z"
              fill="white"
            />
            <path
              d="M12.8382 12.0971C13.0558 12.3375 13.2594 12.4838 13.4491 12.5361C13.6401 12.5871 13.8247 12.5319 14.0028 12.3707C14.1794 12.2107 14.251 12.0352 14.2177 11.8441C14.1844 11.6503 14.0583 11.4325 13.8394 11.1907C13.6218 10.9503 13.4195 10.8055 13.2325 10.7561C13.0455 10.7067 12.863 10.7626 12.6849 10.9239C12.5083 11.0838 12.4353 11.2592 12.4659 11.4502C12.4952 11.6397 12.6193 11.8554 12.8382 12.0971ZM14.517 10.5772C14.8749 10.9724 15.0505 11.3759 15.0439 11.7877C15.0373 12.1994 14.8479 12.5737 14.4758 12.9106C14.2427 13.1217 13.9913 13.2572 13.7214 13.317C13.4502 13.3755 13.1804 13.3524 12.9118 13.2478C12.6432 13.1432 12.3935 12.9635 12.1628 12.7087C11.8036 12.312 11.6279 11.9098 11.6357 11.5023C11.6435 11.0948 11.8349 10.7213 12.2099 10.3817C12.443 10.1707 12.6945 10.0352 12.9643 9.97533C13.2342 9.91544 13.5027 9.93707 13.77 10.0402C14.0373 10.1434 14.2863 10.3224 14.517 10.5772Z"
              fill="white"
            />
            <path
              d="M16.1543 7.62878C16.2585 7.56701 16.3495 7.52327 16.4273 7.49754L16.7967 8.25261C16.7246 8.27267 16.644 8.3091 16.555 8.3619C16.3097 8.50735 16.1556 8.68392 16.0925 8.89161C16.0312 9.0983 16.0677 9.31504 16.2022 9.54183L17.0525 10.9757L16.284 11.4314L14.6133 8.61418L15.1954 8.26898L15.5897 8.67548L15.6275 8.65307C15.6212 8.44335 15.6636 8.24677 15.7546 8.06335C15.8464 7.87725 15.9796 7.73239 16.1543 7.62878Z"
              fill="white"
            />
            <path
              d="M17.4802 5.94003L17.5449 5.98069C17.6215 6.37019 17.6667 6.8665 17.6803 7.4696L17.1006 7.74451C16.9746 7.16439 16.8532 6.68048 16.7364 6.29276L17.4802 5.94003Z"
              fill="white"
            />
            <path
              d="M21.7435 7.97736C21.8426 8.29835 21.8061 8.57739 21.634 8.8145C21.4638 9.05103 21.1567 9.23787 20.7125 9.37501C20.4848 9.44532 20.2858 9.48938 20.1156 9.50722C19.9458 9.52692 19.7818 9.52852 19.6234 9.51202L19.4056 8.8066C19.5872 8.83227 19.7848 8.83975 19.9981 8.82906C20.2134 8.81778 20.3966 8.78881 20.5477 8.74213C20.8575 8.64647 20.9848 8.50907 20.9294 8.32992C20.9087 8.26273 20.8715 8.21495 20.8177 8.18657C20.7634 8.15633 20.6778 8.13064 20.5609 8.1095C20.4434 8.0865 20.2897 8.06548 20.0999 8.04642C19.828 8.01999 19.6219 7.98142 19.4816 7.93073C19.3431 7.87946 19.2307 7.80684 19.1445 7.71287C19.0595 7.61646 18.992 7.48707 18.9418 7.32472C18.856 7.04665 18.897 6.79891 19.065 6.58148C19.2342 6.36161 19.5166 6.19059 19.9122 6.06842C20.2892 5.95202 20.6813 5.9209 21.0884 5.97506L21.021 6.67042C20.8435 6.65164 20.68 6.64183 20.5306 6.64097C20.3811 6.64012 20.2354 6.66159 20.0936 6.70538C19.8417 6.78317 19.7367 6.89019 19.7788 7.02642C19.8024 7.10293 19.863 7.15679 19.9605 7.188C20.0599 7.21863 20.2638 7.24664 20.5721 7.27204C20.8471 7.29546 21.0548 7.33251 21.1952 7.3832C21.3355 7.4339 21.45 7.50687 21.5387 7.60213C21.6274 7.69739 21.6957 7.82247 21.7435 7.97736Z"
              fill="white"
            />
            <path
              d="M25.605 7.83574C25.761 7.82638 25.9461 7.78103 26.1603 7.69969L26.2002 8.36354C25.9839 8.4763 25.7139 8.5424 25.3903 8.56181C25.0335 8.58322 24.7678 8.50916 24.5933 8.33962C24.4206 8.16801 24.3234 7.90187 24.3018 7.54119L24.2072 5.96492L23.7803 5.99054L23.7576 5.61329L24.231 5.28552L24.447 4.57991L25.0172 4.5457L25.059 5.24171L25.9743 5.18679L26.0145 5.85648L25.0992 5.91141L25.1938 7.48767C25.2014 7.6144 25.2421 7.70587 25.3159 7.7621C25.3916 7.81821 25.488 7.84276 25.605 7.83574Z"
              fill="white"
            />
            <path
              d="M29.8872 8.87909L29.0024 8.75397L29.2703 6.85973C29.3365 6.39173 29.1955 6.13312 28.8474 6.08389C28.5999 6.04889 28.4091 6.10772 28.2751 6.26038C28.1411 6.41304 28.0474 6.67792 27.9941 7.05503L27.7783 8.58086L26.8935 8.45574L27.5318 3.94206L28.4166 4.06718L28.2865 4.98674C28.2764 5.05829 28.2459 5.22558 28.1949 5.48861L28.1376 5.74681L28.1841 5.75338C28.4262 5.46411 28.7619 5.34984 29.1912 5.41055C29.5722 5.46443 29.8468 5.60781 30.0151 5.84069C30.1833 6.07358 30.2404 6.38148 30.1862 6.76439L29.8872 8.87909Z"
              fill="white"
            />
            <path
              d="M31.9927 7.82655C31.8842 8.13208 31.8519 8.38071 31.8957 8.57245C31.9414 8.76484 32.0774 8.90123 32.3038 8.98162C32.5283 9.06136 32.7168 9.04124 32.8693 8.92128C33.0242 8.80013 33.1563 8.58587 33.2654 8.2785C33.3739 7.97297 33.4056 7.72618 33.3604 7.53812C33.3153 7.35007 33.1795 7.21584 32.9532 7.13545C32.7286 7.05572 32.5395 7.07458 32.3859 7.19205C32.2329 7.30769 32.1018 7.51918 31.9927 7.82655ZM34.1268 8.58437C33.9484 9.08683 33.6763 9.43273 33.3106 9.62206C32.9449 9.81138 32.5256 9.82206 32.0526 9.65409C31.7562 9.54887 31.5178 9.39164 31.3371 9.18242C31.1571 8.97135 31.0496 8.72277 31.0144 8.43669C30.9791 8.1506 31.0191 7.84559 31.1341 7.52166C31.3132 7.01736 31.584 6.67205 31.9465 6.48576C32.3091 6.29946 32.7287 6.29094 33.2054 6.46022C33.5017 6.56544 33.7402 6.72267 33.9208 6.9319C34.1014 7.14112 34.2097 7.38786 34.2455 7.6721C34.2814 7.95635 34.2418 8.26044 34.1268 8.58437Z"
              fill="white"
            />
            <path
              d="M36.1867 11.7642L36.3214 11.3497L36.2826 11.3235C36.118 11.3963 35.9394 11.4183 35.7467 11.3896C35.5551 11.3593 35.3662 11.2813 35.1801 11.1557C34.8611 10.9405 34.678 10.6933 34.6308 10.414C34.5847 10.1332 34.6698 9.83244 34.8861 9.51187L36.0806 7.74141L36.8213 8.24118L35.7513 9.82706C35.6192 10.023 35.5546 10.1938 35.5575 10.3395C35.5616 10.4836 35.6398 10.607 35.7919 10.7097C35.9992 10.8496 36.1954 10.8818 36.3805 10.8064C36.5667 10.7295 36.768 10.5307 36.9843 10.2101L37.8462 8.93266L38.5869 9.43243L36.755 12.1476L36.1867 11.7642Z"
              fill="white"
            />
            <path
              d="M41.8236 12.4577L41.5007 12.777L41.0465 12.505C41.0082 12.6747 40.9245 12.8234 40.7953 12.951C40.5453 13.1982 40.2637 13.3036 39.9504 13.2673C39.6398 13.231 39.3315 13.0579 39.0253 12.7482L38.9182 12.6274L38.836 12.5234C38.7214 12.5075 38.6336 12.5298 38.5725 12.5902C38.4808 12.6808 38.5503 12.8428 38.781 13.0762L39.1723 13.472C39.425 13.7276 39.5623 13.9763 39.5844 14.2181C39.6079 14.4613 39.5134 14.688 39.3008 14.8981C39.0286 15.1672 38.7049 15.262 38.3297 15.1825C37.9559 15.1044 37.5589 14.8528 37.1387 14.4278C36.8174 14.1028 36.6286 13.7978 36.5722 13.513C36.5158 13.2309 36.5897 12.989 36.7939 12.7871C36.9342 12.6484 37.0948 12.5762 37.2758 12.5703C37.4567 12.5644 37.6511 12.6263 37.8589 12.756C37.8221 12.6632 37.811 12.5617 37.8254 12.4513C37.8413 12.3395 37.8846 12.2486 37.9554 12.1785C38.0443 12.0907 38.1434 12.0436 38.2525 12.0373C38.363 12.0297 38.4948 12.0463 38.6479 12.0872C38.5798 11.9128 38.5685 11.7318 38.6137 11.5443C38.6604 11.3581 38.7629 11.1867 38.9212 11.0302C39.1754 10.7789 39.4536 10.6673 39.7559 10.6953C40.0582 10.7232 40.3611 10.8907 40.6646 11.1977C40.7291 11.263 40.7991 11.3462 40.8744 11.4475C40.9526 11.5488 41.0012 11.6146 41.0204 11.6451L41.8236 12.4577ZM37.3876 13.2877C37.3001 13.3742 37.2726 13.4839 37.3049 13.6167C37.3387 13.7508 37.4304 13.8936 37.5801 14.045C37.8053 14.2728 38.013 14.4204 38.2032 14.4878C38.3934 14.5552 38.5426 14.5353 38.651 14.4282C38.7385 14.3417 38.7611 14.2438 38.719 14.1344C38.6768 14.0251 38.5767 13.8905 38.4188 13.7308L38.0934 13.4016C37.9781 13.2849 37.8542 13.2138 37.7217 13.1882C37.5893 13.1653 37.4779 13.1985 37.3876 13.2877ZM39.5122 11.6447C39.3858 11.7697 39.314 11.897 39.2967 12.0268C39.2807 12.1579 39.3311 12.2825 39.4478 12.4005C39.5659 12.52 39.6892 12.5711 39.8177 12.5539C39.9463 12.5367 40.0738 12.4656 40.2002 12.3406C40.4807 12.0633 40.5071 11.8093 40.2791 11.5787C40.0484 11.3454 39.7928 11.3674 39.5122 11.6447Z"
              fill="white"
            />
            <path
              d="M41.4665 17.7253L40.9729 16.9805L42.5677 15.9237C42.9617 15.6626 43.0616 15.3856 42.8674 15.0925C42.7293 14.8841 42.5587 14.7804 42.3555 14.7815C42.1524 14.7826 41.8921 14.8883 41.5746 15.0987L40.29 15.9499L39.7964 15.205L43.5964 12.687L44.09 13.4318L43.3159 13.9448C43.2556 13.9847 43.1102 14.0729 42.8796 14.2093L42.6485 14.3379L42.6744 14.3769C43.0514 14.3661 43.3597 14.5413 43.5992 14.9028C43.8117 15.2235 43.8867 15.5241 43.8242 15.8045C43.7617 16.085 43.5692 16.332 43.2469 16.5456L41.4665 17.7253Z"
              fill="white"
            />
            <path
              d="M42.9853 19.5859C43.0454 19.7301 43.1491 19.89 43.2963 20.0656L42.6825 20.3215C42.5049 20.1543 42.3537 19.9211 42.229 19.6218C42.0914 19.2919 42.074 19.0167 42.1768 18.7961C42.282 18.5766 42.5014 18.3973 42.8349 18.2583L44.2925 17.6507L44.1279 17.2559L44.4767 17.1105L44.9419 17.4498L45.6793 17.4217L45.8991 17.949L45.2555 18.2173L45.6084 19.0637L44.9891 19.3218L44.6363 18.4754L43.1788 19.083C43.0616 19.1319 42.9886 19.2004 42.9597 19.2886C42.9317 19.3786 42.9402 19.4777 42.9853 19.5859Z"
              fill="white"
            />
            <path
              d="M44.2875 22.9757C43.9622 23.0594 43.6852 23.0096 43.4566 22.8264C43.2285 22.6451 43.0565 22.3293 42.9408 21.8791C42.8814 21.6483 42.8469 21.4475 42.8373 21.2765C42.8257 21.1061 42.8319 20.9421 42.856 20.7847L43.571 20.6008C43.5367 20.7811 43.5198 20.978 43.5203 21.1917C43.5212 21.4072 43.5414 21.5916 43.5808 21.7448C43.6615 22.0588 43.7927 22.1925 43.9743 22.1458C44.0424 22.1282 44.0919 22.0933 44.1228 22.041C44.1556 21.9882 44.1854 21.9039 44.2121 21.7882C44.2407 21.6719 44.269 21.5194 44.2971 21.3307C44.3365 21.0604 44.3849 20.8564 44.4422 20.7186C44.5001 20.5828 44.578 20.474 44.676 20.3923C44.7763 20.3121 44.9088 20.2508 45.0734 20.2085C45.3552 20.136 45.6007 20.1889 45.8099 20.367C46.0214 20.5465 46.1787 20.8368 46.2818 21.2378C46.3801 21.6199 46.3924 22.013 46.3189 22.4171L45.6275 22.3166C45.6548 22.1402 45.6724 21.9773 45.6804 21.8281C45.6884 21.6788 45.6739 21.5323 45.6369 21.3885C45.5713 21.1332 45.4694 21.0232 45.3313 21.0587C45.2538 21.0787 45.1971 21.1366 45.1612 21.2325C45.1259 21.3304 45.0882 21.5327 45.0481 21.8394C45.0115 22.113 44.9646 22.3187 44.9072 22.4564C44.8499 22.5942 44.7715 22.7051 44.6721 22.7891C44.5727 22.8732 44.4445 22.9354 44.2875 22.9757Z"
              fill="white"
            />
          </>
        )}
        {id === 1 && (
          <>
            <path
              d="M8.35496 16.9212L8.49574 16.6576C8.62732 16.4113 8.67753 16.2012 8.64638 16.0273C8.61351 15.8526 8.50405 15.7155 8.31798 15.6161C8.1302 15.5158 7.96391 15.4934 7.81911 15.549C7.67524 15.6028 7.54488 15.7391 7.42802 15.9579L7.23341 16.3222L8.35496 16.9212ZM8.72156 14.7921C9.12814 15.0092 9.37102 15.3028 9.45019 15.6728C9.53028 16.0411 9.44519 16.4595 9.19491 16.9281L9.01134 17.2718L10.3551 17.9895L9.92727 18.7906L6.14916 16.7728L6.79371 15.5659C7.03847 15.1077 7.3234 14.8126 7.6485 14.6806C7.9728 14.5461 8.33048 14.5832 8.72156 14.7921Z"
              fill="white"
            />
            <path
              d="M11.8042 15.5185L8.37677 12.9498L8.92144 12.223L12.3489 14.7918L11.8042 15.5185Z"
              fill="white"
            />
            <path
              d="M10.3271 10.5981L10.4023 10.6114C10.6199 10.9434 10.8485 11.3862 11.0881 11.9399L10.6545 12.4128C10.3195 11.9227 10.0248 11.5201 9.77067 11.2048L10.3271 10.5981Z"
              fill="white"
            />
            <path
              d="M14.9255 10.8276C15.1509 11.0767 15.2358 11.345 15.18 11.6326C15.1257 11.9189 14.9263 12.218 14.5817 12.53C14.405 12.69 14.2433 12.814 14.0965 12.9021C13.951 12.9917 13.803 13.0625 13.6525 13.1144L13.157 12.5672C13.3325 12.5136 13.5147 12.437 13.7035 12.3371C13.8938 12.2359 14.0476 12.1322 14.1649 12.0261C14.4052 11.8085 14.4625 11.6302 14.3366 11.4912C14.2895 11.439 14.2355 11.4115 14.1748 11.4085C14.1128 11.404 14.0244 11.4169 13.9095 11.4471C13.7933 11.4759 13.6451 11.5218 13.465 11.5848C13.2074 11.6757 13.0044 11.7278 12.8557 11.7412C12.7086 11.7533 12.576 11.7349 12.4582 11.6862C12.3404 11.6347 12.2245 11.546 12.1105 11.4201C11.9151 11.2043 11.8476 10.9624 11.908 10.6944C11.9684 10.4236 12.1521 10.1493 12.4591 9.87137C12.7515 9.60657 13.0937 9.41269 13.4856 9.28972L13.7184 9.94841C13.5496 10.0064 13.3973 10.0666 13.2614 10.129C13.1256 10.1914 13.0026 10.2724 12.8926 10.372C12.6971 10.549 12.6473 10.6903 12.7429 10.796C12.7967 10.8554 12.8744 10.8786 12.9759 10.8657C13.079 10.8514 13.2756 10.7907 13.5658 10.6834C13.8249 10.5884 14.0288 10.5342 14.1774 10.5208C14.326 10.5075 14.4607 10.5252 14.5813 10.5741C14.7019 10.6229 14.8167 10.7075 14.9255 10.8276Z"
              fill="white"
            />
            <path
              d="M18.4612 9.14835C18.6024 9.0814 18.7572 8.97018 18.9255 8.81469L19.2105 9.41559C19.052 9.601 18.8262 9.76315 18.5333 9.90207C18.2103 10.0552 17.9362 10.0858 17.711 9.99369C17.4867 9.89902 17.2971 9.68845 17.1423 9.36197L16.4657 7.93515L16.0793 8.11842L15.9173 7.77694L16.234 7.29605L16.1707 6.56086L16.6869 6.31608L16.9857 6.9461L17.8142 6.55321L18.1017 7.1594L17.2731 7.5523L17.9497 8.97911C18.0041 9.09382 18.076 9.16347 18.1655 9.18805C18.2568 9.21179 18.3553 9.19856 18.4612 9.14835Z"
              fill="white"
            />
            <path
              d="M22.7476 8.32952L21.8938 8.59316L21.3294 6.76523C21.1899 6.31362 20.9523 6.13967 20.6163 6.2434C20.3775 6.31716 20.2299 6.45164 20.1737 6.64684C20.1174 6.84205 20.1455 7.1216 20.2579 7.48551L20.7125 8.95792L19.8588 9.22156L18.5138 4.86589L19.3676 4.60226L19.6416 5.48963C19.6629 5.55868 19.7065 5.72305 19.7724 5.98275L19.8306 6.24074L19.8754 6.22691C19.9712 5.86208 20.2263 5.6157 20.6406 5.48777C21.0082 5.37425 21.3177 5.387 21.5692 5.52604C21.8206 5.66508 22.0034 5.91935 22.1175 6.28885L22.7476 8.32952Z"
              fill="white"
            />
            <path
              d="M24.2793 6.53768C24.2987 6.86131 24.366 7.10283 24.4813 7.26223C24.5984 7.42152 24.7769 7.49397 25.0167 7.47958C25.2546 7.46531 25.4203 7.37319 25.5138 7.20322C25.6091 7.03118 25.647 6.78237 25.6275 6.45679C25.608 6.13315 25.5408 5.89358 25.4259 5.73808C25.3109 5.58258 25.1335 5.51202 24.8937 5.52641C24.6558 5.54068 24.4891 5.63188 24.3935 5.80002C24.2978 5.96621 24.2598 6.21209 24.2793 6.53768ZM26.5399 6.40204C26.5718 6.93429 26.4564 7.35895 26.1937 7.67604C25.931 7.99313 25.5491 8.1667 25.048 8.19676C24.7341 8.2156 24.4532 8.16397 24.2052 8.04189C23.9571 7.91785 23.761 7.73102 23.6169 7.48139C23.4727 7.23176 23.3904 6.93538 23.3698 6.59225C23.3378 6.05805 23.4522 5.63442 23.7133 5.32135C23.9743 5.00828 24.3573 4.8366 24.8622 4.8063C25.1761 4.78747 25.457 4.83909 25.705 4.96118C25.953 5.08326 26.149 5.26814 26.293 5.51582C26.437 5.7635 26.5193 6.05891 26.5399 6.40204Z"
              fill="white"
            />
            <path
              d="M29.6812 8.44079L29.621 8.00916L29.5745 8.00259C29.4584 8.14004 29.3075 8.23803 29.1217 8.29657C28.9362 8.35318 28.7322 8.36576 28.5098 8.33431C28.1288 8.28044 27.856 8.1383 27.6913 7.90789C27.5269 7.67555 27.4718 7.36793 27.5259 6.98502L27.825 4.87032L28.7097 4.99543L28.4419 6.88967C28.4088 7.12367 28.4255 7.30554 28.4919 7.43526C28.5587 7.56305 28.683 7.6398 28.8647 7.66551C29.1123 7.70052 29.3028 7.64362 29.4363 7.49483C29.57 7.34411 29.664 7.07729 29.7181 6.69438L29.9339 5.16854L30.8186 5.29366L30.36 8.53678L29.6812 8.44079Z"
              fill="white"
            />
            <path
              d="M35.1484 6.86968L34.975 7.28937L34.4508 7.21543C34.4815 7.38661 34.4622 7.55614 34.3928 7.72401C34.2586 8.04893 34.0401 8.25556 33.7373 8.3439C33.4371 8.43118 33.0857 8.39165 32.6831 8.22533L32.5376 8.15567L32.4213 8.09179C32.3096 8.12172 32.2374 8.1764 32.2046 8.25582C32.1553 8.37496 32.2823 8.49718 32.5856 8.62248L33.1001 8.83505C33.4322 8.97229 33.6555 9.14801 33.7698 9.36221C33.886 9.57716 33.887 9.82273 33.7729 10.0989C33.6267 10.4527 33.3653 10.6659 32.9887 10.7386C32.614 10.8119 32.1504 10.7345 31.598 10.5063C31.1756 10.3317 30.8831 10.1242 30.7204 9.88372C30.5588 9.64577 30.5328 9.39412 30.6425 9.12877C30.7178 8.94645 30.8377 8.81742 31.0021 8.74166C31.1666 8.6659 31.3697 8.64734 31.6116 8.68599C31.5416 8.61483 31.4919 8.52559 31.4622 8.41825C31.4334 8.30911 31.438 8.20851 31.476 8.11645C31.5237 8.00093 31.5967 7.91905 31.6948 7.87084C31.7936 7.82081 31.9215 7.7849 32.0785 7.7631C31.948 7.62887 31.8671 7.46655 31.8359 7.27614C31.8065 7.08647 31.8343 6.88875 31.9193 6.68297C32.0558 6.35263 32.2688 6.14159 32.5581 6.04983C32.8475 5.95808 33.1917 5.99462 33.5906 6.15945C33.6755 6.19451 33.7723 6.24403 33.8811 6.30801C33.9925 6.37093 34.0629 6.41271 34.0924 6.43336L35.1484 6.86968ZM31.3841 9.35914C31.3371 9.47286 31.3544 9.58459 31.4358 9.69433C31.5191 9.80481 31.6591 9.9007 31.8559 9.982C32.1519 10.1043 32.4006 10.1595 32.6021 10.1477C32.8035 10.1358 32.9333 10.0595 32.9915 9.91868C33.0385 9.80496 33.0213 9.70593 32.9399 9.62158C32.8585 9.53724 32.714 9.45218 32.5065 9.36641L32.0786 9.18964C31.927 9.12699 31.7852 9.10961 31.6532 9.13749C31.5223 9.16793 31.4326 9.24181 31.3841 9.35914ZM32.7028 7.01936C32.6349 7.18363 32.6182 7.32888 32.6527 7.45513C32.689 7.58213 32.7838 7.67733 32.9373 7.74072C33.0925 7.80487 33.226 7.80402 33.3377 7.73819C33.4495 7.67235 33.5393 7.5573 33.6071 7.39303C33.7578 7.0284 33.6833 6.78418 33.3837 6.66037C33.0804 6.53507 32.8534 6.65473 32.7028 7.01936Z"
              fill="white"
            />
            <path
              d="M36.9395 11.7863L36.1988 11.2866L37.2687 9.7007C37.5331 9.30888 37.5196 9.01466 37.2281 8.81803C37.0209 8.67821 36.8236 8.64759 36.6363 8.72619C36.449 8.80478 36.2488 9.00193 36.0358 9.31765L35.1739 10.5951L34.4332 10.0953L36.9828 6.31642L37.7235 6.81619L37.2041 7.58606C37.1637 7.64596 37.063 7.783 36.902 7.99716L36.7375 8.20427L36.7764 8.23049C37.1207 8.07638 37.4725 8.12058 37.832 8.36309C38.1509 8.57829 38.3351 8.82742 38.3844 9.11046C38.4338 9.39351 38.3503 9.69532 38.134 10.0159L36.9395 11.7863Z"
              fill="white"
            />
            <path
              d="M39.0634 12.8483C39.1786 12.9539 39.3399 13.0555 39.5473 13.1529L39.0979 13.6431C38.8664 13.566 38.6312 13.4179 38.3923 13.1988C38.1288 12.9572 37.9974 12.7148 37.9979 12.4715C38.0012 12.228 38.1249 11.9732 38.3691 11.7068L39.4364 10.543L39.1211 10.2539L39.3765 9.97534L39.9412 10.0877L40.5985 9.75239L41.0196 10.1385L40.5484 10.6524L41.2242 11.2722L40.7708 11.7666L40.0949 11.1469L39.0277 12.3107C38.9419 12.4043 38.9045 12.4972 38.9154 12.5893C38.9277 12.6828 38.9771 12.7691 39.0634 12.8483Z"
              fill="white"
            />
            <path
              d="M41.6086 15.5062C41.337 15.704 41.0612 15.7599 40.7811 15.674C40.5022 15.5896 40.2259 15.3596 39.9522 14.9838C39.8119 14.7912 39.7058 14.6173 39.6337 14.4619C39.5601 14.3078 39.5054 14.1531 39.4697 13.9979L40.0664 13.5632C40.101 13.7434 40.1579 13.9327 40.2372 14.1311C40.3176 14.331 40.4044 14.495 40.4976 14.6228C40.6884 14.8849 40.8597 14.9608 41.0112 14.8504C41.0681 14.809 41.1012 14.7582 41.1106 14.6982C41.1216 14.637 41.1182 14.5477 41.1003 14.4303C41.084 14.3117 41.0541 14.1595 41.0106 13.9737C40.9475 13.708 40.9172 13.5005 40.9197 13.3513C40.9233 13.2037 40.9556 13.0738 41.0165 12.9618C41.0802 12.8502 41.1807 12.7443 41.318 12.6443C41.5533 12.4729 41.801 12.4315 42.0611 12.5199C42.3239 12.6087 42.5772 12.8205 42.821 13.1552C43.0533 13.4741 43.2098 13.8349 43.2905 14.2376L42.6108 14.3992C42.5711 14.2252 42.5274 14.0674 42.4798 13.9257C42.4321 13.784 42.3646 13.6531 42.2772 13.5332C42.122 13.32 41.9867 13.2554 41.8715 13.3394C41.8067 13.3865 41.7754 13.4613 41.7775 13.5637C41.7807 13.6676 41.8203 13.8696 41.8962 14.1695C41.9632 14.4373 41.9954 14.6457 41.9929 14.7949C41.9905 14.9441 41.9585 15.0761 41.8972 15.1909C41.8358 15.3057 41.7396 15.4108 41.6086 15.5062Z"
              fill="white"
            />
          </>
        )}
        {id === 2 && (
          <>
            <path
              d="M8.07959 34.7671L7.96461 34.4912C7.85714 34.2335 7.72625 34.0616 7.57194 33.9757C7.41582 33.8905 7.24041 33.8885 7.04572 33.9697C6.84922 34.0516 6.72664 34.1662 6.67799 34.3134C6.62859 34.4589 6.65161 34.6461 6.74705 34.875L6.906 35.2563L8.07959 34.7671ZM6.66313 33.1357C7.08858 32.9583 7.46959 32.9561 7.80616 33.129C8.14198 33.3 8.41209 33.6307 8.61651 34.1211L8.76644 34.4807L10.1726 33.8946L10.522 34.7328L6.56861 36.3809L6.04216 35.1181C5.84226 34.6386 5.79411 34.2312 5.89772 33.896C5.99877 33.5597 6.25391 33.3063 6.66313 33.1357Z"
              fill="white"
            />
            <path
              d="M9.17674 31.2084L5.02847 32.275L4.8023 31.3954L8.95057 30.3288L9.17674 31.2084Z"
              fill="white"
            />
            <path
              d="M8.55545 25.9921C8.5593 26.3769 8.42267 26.6801 8.14556 26.9016L8.14603 26.9485C8.41915 26.9145 8.5772 26.8973 8.62017 26.8969L9.94731 26.8836L9.95625 27.7771L5.23945 27.8243L5.23219 27.0978L5.65573 26.9675L5.65532 26.9265C5.32901 26.7208 5.16381 26.4128 5.15971 26.0027C5.15584 25.6159 5.30223 25.3117 5.59887 25.09C5.89551 24.8682 6.30945 24.7547 6.8407 24.7494C7.19031 24.7459 7.49454 24.7946 7.75339 24.8956C8.01222 24.9946 8.20996 25.1381 8.34662 25.3262C8.48328 25.5143 8.55289 25.7363 8.55545 25.9921ZM5.87742 26.2826C5.87963 26.5033 5.9496 26.6638 6.08733 26.764C6.22312 26.8642 6.44728 26.9147 6.75981 26.9155L6.85649 26.9145C7.20806 26.911 7.45949 26.8567 7.6108 26.7517C7.76209 26.6447 7.8366 26.4779 7.83434 26.2513C7.83033 25.8509 7.49825 25.6541 6.8381 25.6607C6.51583 25.6639 6.27512 25.7161 6.11596 25.8173C5.95482 25.9166 5.87531 26.0717 5.87742 26.2826Z"
              fill="white"
            />
            <path
              d="M9.08694 21.9669L8.61315 22.0277L8.60752 22.0504C8.7553 22.2419 8.84599 22.4243 8.87957 22.5976C8.91172 22.7686 8.89707 22.9782 8.83562 23.2266C8.76009 23.5318 8.6133 23.751 8.39525 23.8842C8.17766 24.0155 7.90771 24.0412 7.5854 23.9615C7.24792 23.878 7.02864 23.699 6.92755 23.4245C6.82503 23.1476 6.82411 22.7691 6.92479 22.289L7.04423 21.7331L6.90488 21.6986C6.58257 21.6188 6.3806 21.7439 6.29898 22.0738C6.23612 22.3279 6.23902 22.6455 6.30769 23.0267L5.65077 23.1689C5.54981 22.7556 5.55445 22.3262 5.66469 21.8807C5.77024 21.4541 5.94406 21.15 6.18616 20.9685C6.42825 20.7869 6.7389 20.7431 7.11809 20.8369L9.23682 21.3611L9.08694 21.9669ZM7.55045 21.8583L7.4788 22.1967C7.42399 22.4508 7.42305 22.6497 7.47598 22.7936C7.52891 22.9375 7.6407 23.0305 7.81133 23.0727C8.05591 23.1333 8.21291 23.0232 8.28234 22.7426C8.33207 22.5416 8.31388 22.3671 8.22778 22.2191C8.14215 22.0691 8.00359 21.9704 7.8121 21.9231L7.55045 21.8583Z"
              fill="white"
            />
            <path
              d="M9.43258 18.9524C9.49225 18.808 9.53226 18.6216 9.55262 18.3934L10.1673 18.6473C10.1743 18.8911 10.1159 19.1629 9.99209 19.4625C9.85559 19.7929 9.67299 19.9995 9.44427 20.0825C9.21449 20.163 8.93262 20.1342 8.59868 19.9963L7.13925 19.3932L6.97591 19.7885L6.62662 19.6442L6.5384 19.0752L5.99784 18.5729L6.21601 18.0449L6.86043 18.3112L7.21061 17.4637L7.83066 17.7199L7.48048 18.5674L8.93991 19.1704C9.05724 19.2189 9.15731 19.2222 9.24012 19.1803C9.32367 19.1367 9.38783 19.0607 9.43258 18.9524Z"
              fill="white"
            />
            <path
              d="M6.93944 16.2158C6.68275 16.0787 6.63077 15.8671 6.78351 15.5811C6.93625 15.2952 7.14097 15.2207 7.39767 15.3578C7.51999 15.4231 7.59628 15.5104 7.62655 15.6196C7.65601 15.7261 7.63256 15.8508 7.55619 15.9938C7.40344 16.2798 7.19786 16.3538 6.93944 16.2158ZM11.0143 17.3359L10.5934 18.1241L7.70422 16.5811L8.12518 15.7929L11.0143 17.3359Z"
              fill="white"
            />
            <path
              d="M10.2544 13.7245C10.1333 13.8702 10.085 14.0231 10.1094 14.1831C10.1324 14.3418 10.2237 14.4978 10.3835 14.6509L11.185 13.6865C11.0127 13.5485 10.8452 13.4842 10.6825 13.4936C10.5182 13.5018 10.3755 13.5788 10.2544 13.7245ZM12.4573 15.3917C12.1202 15.7972 11.7449 16.0211 11.3313 16.0634C10.9177 16.1057 10.5059 15.9564 10.0959 15.6156C9.6738 15.2648 9.43525 14.8888 9.38023 14.4875C9.32494 14.0834 9.45211 13.6951 9.76172 13.3226C10.0576 12.9667 10.3893 12.7738 10.7569 12.7441C11.1245 12.7143 11.487 12.848 11.8445 13.1452L12.1779 13.4223L10.8277 15.0468C11.0292 15.2016 11.2298 15.2704 11.4293 15.2534C11.6288 15.2364 11.8097 15.1303 11.972 14.935C12.0981 14.7833 12.2016 14.6268 12.2824 14.4654C12.3632 14.304 12.4303 14.121 12.4835 13.9163L13.0152 14.3583C12.9754 14.5284 12.913 14.6936 12.8279 14.854C12.7444 15.0157 12.6208 15.1949 12.4573 15.3917Z"
              fill="white"
            />
            <path
              d="M16.1094 11.8777L15.4159 12.4412L14.2094 10.9566C14.0604 10.7732 13.9163 10.6625 13.7772 10.6245C13.6369 10.585 13.4955 10.6232 13.353 10.7389C13.159 10.8966 13.0718 11.0757 13.0913 11.2763C13.1096 11.4753 13.2407 11.7249 13.4846 12.025L14.4565 13.2209L13.7631 13.7845L11.6974 11.2426L12.2271 10.8121L12.5845 11.0615L12.6232 11.0301C12.6007 10.8445 12.6317 10.6658 12.7161 10.494C12.8007 10.3195 12.9295 10.162 13.1023 10.0216C13.3978 9.7814 13.6874 9.67943 13.9711 9.71568C14.2535 9.75042 14.5173 9.91861 14.7624 10.2202L16.1094 11.8777Z"
              fill="white"
            />
            <path
              d="M17.8229 10.1934C17.9607 10.1198 18.11 10.0013 18.2708 9.83798L18.584 10.4246C18.4346 10.6174 18.2168 10.7901 17.9308 10.9428C17.6155 11.1112 17.3432 11.1547 17.1138 11.0735C16.8853 10.9896 16.6859 10.7883 16.5157 10.4696L15.7719 9.0766L15.3946 9.27807L15.2166 8.94469L15.51 8.44926L15.4118 7.71792L15.9157 7.44883L16.2442 8.06391L17.0531 7.63199L17.3691 8.22381L16.5602 8.65573L17.3039 10.0487C17.3637 10.1607 17.4389 10.2268 17.5294 10.2471C17.6217 10.2665 17.7195 10.2486 17.8229 10.1934Z"
              fill="white"
            />
            <path
              d="M22.0068 9.41977C21.0194 9.67366 20.3863 9.25866 20.1076 8.17478C19.969 7.63568 19.9975 7.18972 20.1932 6.83691C20.3883 6.48222 20.7365 6.24042 21.2378 6.11153C21.6048 6.01717 21.9524 6.00442 22.2807 6.07328L22.1975 6.80857C22.0472 6.78873 21.909 6.77687 21.783 6.77297C21.6565 6.76719 21.5346 6.77937 21.4173 6.80953C20.9671 6.92529 20.8242 7.30285 20.9886 7.94221C21.1481 8.56265 21.453 8.81499 21.9032 8.69923C22.0696 8.65643 22.2182 8.59504 22.3489 8.51505C22.4791 8.43318 22.603 8.32671 22.7205 8.19565L22.9109 8.93621C22.7938 9.06111 22.6678 9.15906 22.5327 9.23008C22.3996 9.3006 22.2243 9.36383 22.0068 9.41977Z"
              fill="white"
            />
            <path
              d="M24.3203 7.31697C24.3397 7.64061 24.4071 7.88213 24.5223 8.04153C24.6395 8.20081 24.8179 8.27326 25.0577 8.25887C25.2956 8.2446 25.4613 8.15248 25.5548 7.98252C25.6501 7.81048 25.688 7.56167 25.6685 7.23608C25.6491 6.91245 25.5819 6.67288 25.4669 6.51738C25.3519 6.36188 25.1745 6.29132 24.9347 6.30571C24.6968 6.31998 24.5301 6.41118 24.4345 6.57932C24.3389 6.7455 24.3008 6.99139 24.3203 7.31697ZM26.5809 7.18134C26.6128 7.71359 26.4974 8.13825 26.2347 8.45534C25.972 8.77242 25.5901 8.946 25.089 8.97606C24.7751 8.99489 24.4942 8.94327 24.2462 8.82118C23.9981 8.69715 23.802 8.51032 23.6579 8.26069C23.5138 8.01106 23.4314 7.71468 23.4108 7.37154C23.3788 6.83735 23.4933 6.41372 23.7543 6.10065C24.0153 5.78758 24.3983 5.61589 24.9032 5.5856C25.2171 5.56676 25.498 5.61839 25.746 5.74047C25.994 5.86256 26.19 6.04744 26.334 6.29512C26.478 6.5428 26.5603 6.83821 26.5809 7.18134Z"
              fill="white"
            />
            <path
              d="M30.4094 9.41809L29.5318 9.24959L29.8926 7.37083C29.9372 7.13874 29.9293 6.95723 29.8689 6.82631C29.8089 6.69347 29.6887 6.60974 29.5084 6.57512C29.2629 6.52797 29.0697 6.57638 28.9287 6.72034C28.788 6.86238 28.6812 7.12329 28.6083 7.50307L28.3177 9.01644L27.4402 8.84793L28.0579 5.63131L28.7282 5.76004L28.7672 6.19412L28.8161 6.20351C28.9438 6.06693 29.1005 5.97571 29.2863 5.92985C29.4744 5.88244 29.6778 5.87973 29.8964 5.92172C30.2705 5.99354 30.5348 6.14971 30.6895 6.39023C30.8445 6.62883 30.8854 6.93897 30.8121 7.32067L30.4094 9.41809Z"
              fill="white"
            />
            <path
              d="M32.0243 9.94571L32.1216 6.44195L32.9854 6.79884L32.8576 8.76527C32.8323 9.01054 32.7875 9.23401 32.7232 9.43567L32.7395 9.44238C32.8201 9.27282 32.9461 9.08292 33.1175 8.87267L34.4123 7.38844L35.2761 7.74533L32.8718 10.2959L32.0243 9.94571Z"
              fill="white"
            />
            <path
              d="M37.0977 9.50545C36.9406 9.39948 36.7837 9.36665 36.6269 9.40696C36.4712 9.44564 36.3252 9.55209 36.1888 9.7263L37.2283 10.4276C37.3485 10.2425 37.3957 10.0694 37.3701 9.90836C37.3455 9.74571 37.2547 9.61141 37.0977 9.50545ZM35.6586 11.8637C35.2214 11.5687 34.9612 11.2176 34.8778 10.8103C34.7945 10.403 34.9019 9.97838 35.2002 9.53637C35.5071 9.08141 35.8575 8.80654 36.2513 8.71176C36.6478 8.61645 37.0469 8.70425 37.4484 8.97516C37.8321 9.23406 38.0571 9.54489 38.1233 9.90766C38.1896 10.2704 38.0927 10.6445 37.8327 11.0298L37.5902 11.3893L35.8392 10.2078C35.7053 10.4238 35.6567 10.6302 35.6936 10.827C35.7304 11.0239 35.854 11.1933 36.0645 11.3353C36.228 11.4456 36.3941 11.533 36.5628 11.5973C36.7314 11.6616 36.9202 11.71 37.1292 11.7426L36.7425 12.3158C36.5692 12.2931 36.3986 12.2475 36.2305 12.1788C36.0613 12.1118 35.8707 12.0068 35.6586 11.8637Z"
              fill="white"
            />
            <path
              d="M40.938 11.812C41.0272 11.8939 41.0954 11.9683 41.1426 12.0354L40.5266 12.6074C40.4865 12.5442 40.4284 12.4776 40.3521 12.4076C40.1419 12.2149 39.9283 12.1184 39.7113 12.1181C39.4956 12.1192 39.2987 12.2169 39.1205 12.4112L37.9939 13.6398L37.3353 13.0359L39.549 10.6218L40.0478 11.0792L39.7727 11.5743L39.8051 11.604C40.004 11.5373 40.2045 11.521 40.4064 11.5551C40.6111 11.5891 40.7883 11.6747 40.938 11.812Z"
              fill="white"
            />
            <path
              d="M41.4269 16.2152C41.1554 16.413 40.8796 16.4689 40.5995 16.383C40.3206 16.2986 40.0443 16.0685 39.7706 15.6928C39.6303 15.5002 39.5241 15.3262 39.4521 15.1709C39.3784 15.0168 39.3237 14.8621 39.288 14.7069L39.8848 14.2722C39.9194 14.4524 39.9763 14.6417 40.0556 14.8401C40.136 15.04 40.2228 15.204 40.3159 15.3318C40.5068 15.5939 40.678 15.6697 40.8296 15.5593C40.8864 15.5179 40.9195 15.4672 40.929 15.4072C40.94 15.346 40.9366 15.2567 40.9187 15.1393C40.9023 15.0207 40.8724 14.8685 40.8289 14.6827C40.7658 14.4169 40.7355 14.2095 40.738 14.0603C40.7416 13.9126 40.7739 13.7828 40.8349 13.6708C40.8985 13.5591 40.9991 13.4533 41.1364 13.3533C41.3716 13.1819 41.6193 13.1405 41.8794 13.2289C42.1423 13.3177 42.3956 13.5295 42.6394 13.8642C42.8717 14.1831 43.0282 14.5439 43.1089 14.9466L42.4292 15.1082C42.3894 14.9342 42.3457 14.7763 42.2981 14.6347C42.2505 14.493 42.183 14.3621 42.0956 14.2421C41.9403 14.029 41.8051 13.9644 41.6898 14.0484C41.6251 14.0955 41.5938 14.1703 41.5959 14.2727C41.5991 14.3766 41.6387 14.5786 41.7146 14.8785C41.7815 15.1462 41.8138 15.3547 41.8113 15.5039C41.8088 15.6531 41.7769 15.7851 41.7155 15.8999C41.6542 16.0147 41.558 16.1198 41.4269 16.2152Z"
              fill="white"
            />
            <path
              d="M41.9617 19.2332L42.29 18.8862L42.28 18.865C42.0444 18.8103 41.8587 18.7265 41.723 18.6136C41.59 18.5016 41.4686 18.33 41.359 18.0988C41.2243 17.8147 41.1992 17.5521 41.2837 17.311C41.3691 17.0716 41.5617 16.8808 41.8618 16.7385C42.1759 16.5896 42.459 16.5894 42.711 16.7379C42.9656 16.8874 43.2058 17.1798 43.4318 17.6152L43.6912 18.1213L43.8209 18.0598C44.1209 17.9175 44.1981 17.6928 44.0525 17.3858C43.9403 17.1493 43.7371 16.9052 43.4427 16.6536L43.8612 16.1276C44.2009 16.3837 44.4691 16.7191 44.6658 17.1338C44.8541 17.5309 44.912 17.8763 44.8394 18.1701C44.7669 18.4639 44.5542 18.6945 44.2012 18.8618L42.2291 19.797L41.9617 19.2332ZM43.22 18.3447L43.0613 18.0374C42.9429 17.806 42.8177 17.6514 42.6857 17.5735C42.5536 17.4956 42.4082 17.4943 42.2494 17.5696C42.0217 17.6776 41.9698 17.8621 42.0937 18.1233C42.1824 18.3104 42.3069 18.434 42.4673 18.4941C42.6285 18.556 42.7982 18.5447 42.9764 18.4602L43.22 18.3447Z"
              fill="white"
            />
            <path
              d="M43.5696 21.793C43.6157 21.9423 43.7036 22.1114 43.8335 22.3003L43.1981 22.4965C43.0372 22.3131 42.9089 22.0665 42.8133 21.7568C42.7078 21.4152 42.7167 21.1396 42.84 20.9298C42.9657 20.7213 43.2012 20.5638 43.5465 20.4572L45.0553 19.9913L44.9291 19.5826L45.2902 19.4711L45.7209 19.8532L46.4576 19.8955L46.6262 20.4414L45.96 20.6471L46.2305 21.5233L45.5895 21.7212L45.3189 20.8451L43.8101 21.311C43.6888 21.3484 43.6096 21.4097 43.5725 21.4947C43.536 21.5816 43.535 21.6811 43.5696 21.793Z"
              fill="white"
            />
            <path
              d="M47.2699 22.4973C47.5574 22.4522 47.7263 22.5899 47.7764 22.9102C47.8266 23.2305 47.7079 23.4132 47.4204 23.4582C47.2834 23.4797 47.1699 23.4559 47.0801 23.387C46.9924 23.3196 46.9361 23.2059 46.911 23.0457C46.8608 22.7254 46.9805 22.5426 47.2699 22.4973ZM43.3416 24.0554L43.2034 23.1726L46.4393 22.6659L46.5776 23.5487L43.3416 24.0554Z"
              fill="white"
            />
            <path
              d="M45.1358 25.6852C44.8116 25.6884 44.567 25.7436 44.4021 25.8507C44.2371 25.9598 44.1558 26.1345 44.1583 26.3747C44.1606 26.613 44.2444 26.7831 44.4095 26.8849C44.5765 26.9887 44.8232 27.039 45.1493 27.0358C45.4736 27.0325 45.7162 26.9773 45.8772 26.8703C46.0383 26.7632 46.1176 26.5895 46.1152 26.3493C46.1128 26.111 46.0301 25.9399 45.8669 25.8361C45.7057 25.7322 45.462 25.6819 45.1358 25.6852ZM45.1585 27.9498C44.6253 27.9551 44.2069 27.8187 43.9033 27.5404C43.5997 27.2622 43.4454 26.8721 43.4404 26.3702C43.4372 26.0557 43.5028 25.7777 43.6371 25.5361C43.7734 25.2945 43.9698 25.1079 44.2263 24.9764C44.4828 24.8449 44.783 24.7775 45.1267 24.774C45.6619 24.7687 46.0793 24.9042 46.379 25.1805C46.6786 25.4568 46.831 25.8479 46.836 26.3538C46.8392 26.6682 46.7736 26.9463 46.6393 27.1879C46.505 27.4295 46.3105 27.616 46.056 27.7475C45.8014 27.8789 45.5022 27.9464 45.1585 27.9498Z"
              fill="white"
            />
            <path
              d="M42.706 31.6481L42.9206 30.7807L44.7777 31.2402C45.0071 31.297 45.1888 31.2986 45.3227 31.2453C45.4586 31.1924 45.5485 31.0768 45.5926 30.8986C45.6527 30.6559 45.6145 30.4603 45.4782 30.3119C45.3438 30.164 45.0889 30.0436 44.7135 29.9507L43.2176 29.5806L43.4322 28.7132L46.6117 29.4999L46.4477 30.1625L46.0122 30.1785L46.0003 30.2268C46.1299 30.3615 46.2127 30.5228 46.2487 30.7108C46.2861 30.9011 46.2781 31.1044 46.2246 31.3205C46.1331 31.6902 45.9632 31.946 45.7149 32.0877C45.4684 32.23 45.1565 32.2544 44.7792 32.1611L42.706 31.6481Z"
              fill="white"
            />
          </>
        )}
        {id === 3 && (
          <>
            <path
              d="M6.23748 26.1658L6.23449 25.867C6.2317 25.5877 6.17492 25.3792 6.06416 25.2417C5.95145 25.1041 5.78962 25.0364 5.57868 25.0385C5.36579 25.0406 5.20915 25.1008 5.10876 25.219C5.00835 25.3352 4.95938 25.5174 4.96187 25.7654L4.966 26.1785L6.23748 26.1658ZM5.53729 24.1218C5.99822 24.1172 6.35221 24.2582 6.59924 24.5448C6.84625 24.8295 6.97241 25.2375 6.97772 25.7687L6.98162 26.1584L8.50506 26.1431L8.51414 27.0513L4.23094 27.0942L4.21726 25.726C4.21206 25.2065 4.32042 24.8108 4.54233 24.539C4.76228 24.2653 5.09393 24.1263 5.53729 24.1218Z"
              fill="white"
            />
            <path
              d="M9.126 21.3477L8.65221 21.4086L8.64658 21.4313C8.79437 21.6228 8.88505 21.8052 8.91863 21.9785C8.95078 22.1494 8.93613 22.3591 8.87468 22.6074C8.79915 22.9127 8.65236 23.1319 8.43431 23.2651C8.21673 23.3963 7.94678 23.4221 7.62446 23.3423C7.28698 23.2588 7.0677 23.0798 6.96661 22.8053C6.86409 22.5285 6.86317 22.15 6.96385 21.6699L7.0833 21.1139L6.94394 21.0795C6.62163 20.9997 6.41966 21.1248 6.33804 21.4547C6.27518 21.7087 6.27808 22.0263 6.34675 22.4075L5.68983 22.5498C5.58887 22.1365 5.59352 21.7071 5.70376 21.2615C5.8093 20.8349 5.98313 20.5309 6.22522 20.3493C6.46732 20.1678 6.77796 20.1239 7.15715 20.2177L9.27588 20.742L9.126 21.3477ZM7.58952 21.2392L7.51786 21.5776C7.46305 21.8316 7.46212 22.0306 7.51505 22.1745C7.56798 22.3183 7.67976 22.4114 7.8504 22.4536C8.09497 22.5141 8.25198 22.4041 8.3214 22.1235C8.37113 21.9225 8.35294 21.748 8.26684 21.5999C8.18121 21.45 8.04265 21.3513 7.85116 21.3039L7.58952 21.2392Z"
              fill="white"
            />
            <path
              d="M9.51266 18.3411C9.57232 18.1966 9.61234 18.0103 9.63269 17.782L10.2473 18.036C10.2544 18.2798 10.196 18.5515 10.0722 18.8512C9.93567 19.1815 9.75307 19.3882 9.52435 19.4712C9.29456 19.5517 9.0127 19.5229 8.67876 19.3849L7.21933 18.7819L7.05599 19.1772L6.7067 19.0329L6.61848 18.4639L6.07792 17.9616L6.29608 17.4336L6.94051 17.6999L7.29068 16.8524L7.91074 17.1086L7.56056 17.9561L9.01999 18.5591C9.13732 18.6076 9.23739 18.6109 9.3202 18.569C9.40375 18.5253 9.46791 18.4494 9.51266 18.3411Z"
              fill="white"
            />
            <path
              d="M7.11335 15.3913C6.86424 15.2408 6.82349 15.0268 6.99111 14.7493C7.15873 14.4718 7.36709 14.4082 7.6162 14.5587C7.7349 14.6304 7.80648 14.7215 7.83095 14.8321C7.85475 14.9401 7.82475 15.0634 7.74094 15.2022C7.57333 15.4797 7.36413 15.5427 7.11335 15.3913ZM11.1235 16.7248L10.6615 17.4897L7.85779 15.7964L8.31975 15.0315L11.1235 16.7248Z"
              fill="white"
            />
            <path
              d="M10.3638 13.1347C10.2427 13.2804 10.1943 13.4332 10.2188 13.5932C10.2418 13.752 10.3331 13.9079 10.4928 14.061L11.2943 13.0967C11.1221 12.9586 10.9546 12.8943 10.7918 12.9038C10.6275 12.912 10.4849 12.989 10.3638 13.1347ZM12.5666 14.8018C12.2296 15.2074 11.8542 15.4313 11.4407 15.4736C11.0271 15.5159 10.6153 15.3666 10.2052 15.0258C9.78318 14.675 9.54463 14.2989 9.4896 13.8976C9.43432 13.4936 9.56148 13.1053 9.8711 12.7328C10.167 12.3768 10.4987 12.184 10.8663 12.1542C11.2338 12.1245 11.5964 12.2582 11.9538 12.5553L12.2873 12.8325L10.9371 14.4569C11.1386 14.6117 11.3391 14.6806 11.5387 14.6636C11.7382 14.6466 11.9191 14.5404 12.0814 14.3452C12.2075 14.1935 12.311 14.0369 12.3918 13.8755C12.4726 13.7141 12.5396 13.5311 12.5929 13.3265L13.1246 13.7684C13.0848 13.9385 13.0224 14.1038 12.9373 14.2642C12.8537 14.4258 12.7302 14.6051 12.5666 14.8018Z"
              fill="white"
            />
            <path
              d="M16.25 11.3171L15.5566 11.8807L14.35 10.396C14.201 10.2126 14.0569 10.1019 13.9178 10.064C13.7775 10.0245 13.6361 10.0626 13.4937 10.1784C13.2996 10.3361 13.2124 10.5152 13.2319 10.7157C13.2503 10.9148 13.3814 11.1644 13.6252 11.4645L14.5971 12.6604L13.9037 13.2239L11.838 10.6821L12.3677 10.2516L12.7252 10.5009L12.7638 10.4695C12.7413 10.2839 12.7723 10.1052 12.8567 9.93347C12.9414 9.75895 13.0701 9.60148 13.2429 9.46105C13.5385 9.22085 13.8281 9.11888 14.1117 9.15514C14.3942 9.18988 14.6579 9.35806 14.9031 9.65969L16.25 11.3171Z"
              fill="white"
            />
            <path
              d="M17.9928 9.65236C18.1307 9.57877 18.28 9.4603 18.4407 9.29697L18.754 9.88361C18.6045 10.0764 18.3867 10.2491 18.1007 10.4018C17.7854 10.5701 17.5131 10.6137 17.2837 10.5325C17.0552 10.4486 16.8558 10.2473 16.6856 9.92855L15.9419 8.53559L15.5645 8.73705L15.3865 8.40367L15.68 7.90824L15.5817 7.1769L16.0857 6.90782L16.4141 7.52289L17.223 7.09098L17.539 7.6828L16.7301 8.11471L17.4739 9.50768C17.5337 9.61967 17.6088 9.68581 17.6994 9.7061C17.7916 9.72547 17.8895 9.70756 17.9928 9.65236Z"
              fill="white"
            />
            <path
              d="M18.4401 5.50976L18.5028 5.55346C18.5607 5.94618 18.582 6.44407 18.5668 7.04714L17.9746 7.29402C17.8765 6.70855 17.7784 6.21938 17.6802 5.82652L18.4401 5.50976Z"
              fill="white"
            />
            <path
              d="M22.5949 7.75152C22.6785 8.07688 22.6288 8.35386 22.4455 8.58247C22.2642 8.81059 21.9485 8.98253 21.4983 9.09829C21.2675 9.15763 21.0666 9.19214 20.8957 9.20181C20.7252 9.21337 20.5613 9.20713 20.4038 9.18307L20.22 8.46805C20.4002 8.50237 20.5972 8.51929 20.8108 8.51881C21.0263 8.51784 21.2107 8.49766 21.3639 8.45826C21.6779 8.37752 21.8116 8.24635 21.7649 8.06476C21.7474 7.99666 21.7125 7.94716 21.6602 7.91624C21.6074 7.88344 21.5231 7.85368 21.4073 7.82698C21.2911 7.79839 21.1386 7.77004 20.9498 7.74194C20.6796 7.70253 20.4755 7.65416 20.3378 7.59682C20.2019 7.53898 20.0931 7.46108 20.0115 7.36309C19.9312 7.26273 19.8699 7.13026 19.8276 6.96569C19.7552 6.68385 19.808 6.43835 19.9861 6.22919C20.1657 6.01766 20.456 5.86034 20.857 5.75723C21.2391 5.65898 21.6322 5.64663 22.0363 5.72019L21.9357 6.41154C21.7593 6.3843 21.5965 6.36668 21.4472 6.35868C21.2979 6.35068 21.1514 6.36516 21.0077 6.40212C20.7523 6.46779 20.6424 6.56966 20.6779 6.70775C20.6978 6.7853 20.7558 6.842 20.8517 6.87783C20.9495 6.91318 21.1518 6.9509 21.4586 6.99101C21.7321 7.02755 21.9378 7.07449 22.0756 7.13183C22.2133 7.18918 22.3242 7.26754 22.4083 7.36693C22.4923 7.46632 22.5545 7.59452 22.5949 7.75152Z"
              fill="white"
            />
            <path
              d="M26.4626 7.77741C26.6189 7.77585 26.8061 7.73979 27.0241 7.66925L27.0308 8.33429C26.8091 8.43612 26.5362 8.48866 26.212 8.4919C25.8545 8.49548 25.5929 8.40824 25.427 8.2302C25.2631 8.05018 25.1793 7.7795 25.1757 7.41817L25.1599 5.83907L24.7322 5.84335L24.7284 5.46542L25.2176 5.16167L25.4685 4.46769L26.0398 4.46197L26.0468 5.15924L26.9638 5.15007L26.9705 5.82097L26.0535 5.83014L26.0693 7.40924C26.0705 7.53619 26.1066 7.62959 26.1776 7.68943C26.2504 7.74926 26.3455 7.77858 26.4626 7.77741Z"
              fill="white"
            />
            <path
              d="M30.6691 9.02942L29.7916 8.86091L30.1524 6.98215C30.2415 6.51798 30.1135 6.25274 29.7682 6.18644C29.5227 6.1393 29.3292 6.18866 29.1879 6.33454C29.0465 6.48042 28.9399 6.74037 28.8681 7.1144L28.5775 8.62777L27.6999 8.45926L28.5596 3.98246L29.4371 4.15096L29.262 5.06301C29.2484 5.13398 29.2096 5.29957 29.1457 5.55976L29.0759 5.81484L29.1219 5.82368C29.3779 5.54668 29.7189 5.44907 30.1447 5.53083C30.5226 5.60339 30.7898 5.76012 30.9464 6.001C31.103 6.24189 31.1448 6.55222 31.0719 6.932L30.6691 9.02942Z"
              fill="white"
            />
            <path
              d="M32.8279 8.06114C32.7041 8.36078 32.6593 8.60746 32.6933 8.80117C32.7292 8.99563 32.8582 9.13873 33.0802 9.23047C33.3004 9.32146 33.4897 9.31091 33.648 9.19881C33.8089 9.08565 33.9516 8.87835 34.0762 8.57689C34.2 8.27725 34.2441 8.03237 34.2085 7.84227C34.173 7.65217 34.0442 7.51125 33.8221 7.41951C33.6019 7.32852 33.4121 7.3378 33.2527 7.44734C33.0941 7.55509 32.9525 7.75968 32.8279 8.06114ZM34.921 8.92595C34.7173 9.41875 34.4281 9.75044 34.0533 9.92102C33.6785 10.0916 33.2592 10.0811 32.7953 9.88937C32.5047 9.76928 32.2744 9.60019 32.1046 9.3821C31.9356 9.16219 31.8407 8.90849 31.82 8.62099C31.7993 8.33349 31.8546 8.03089 31.9859 7.7132C32.1902 7.2186 32.4782 6.88744 32.8497 6.71972C33.2212 6.552 33.6407 6.56473 34.1082 6.75791C34.3988 6.87799 34.629 7.04708 34.7989 7.26518C34.9687 7.48327 35.0643 7.73517 35.0857 8.02086C35.1072 8.30656 35.0522 8.60826 34.921 8.92595Z"
              fill="white"
            />
            <path
              d="M36.8305 12.1941L36.9857 11.7869L36.9482 11.7588C36.7803 11.8232 36.6008 11.8363 36.4098 11.798C36.2199 11.7582 36.0352 11.6709 35.8555 11.5361C35.5477 11.3053 35.3771 11.0492 35.3439 10.768C35.3119 10.4852 35.4119 10.1891 35.6439 9.87969L36.9254 8.17109L37.6402 8.70723L36.4924 10.2377C36.3506 10.4268 36.2775 10.5941 36.2732 10.7398C36.2701 10.884 36.342 11.0111 36.4889 11.1213C36.6889 11.2713 36.8832 11.3133 37.0719 11.2473C37.2617 11.1797 37.4727 10.9912 37.7047 10.6818L38.6293 9.44902L39.3441 9.98516L37.3789 12.6055L36.8305 12.1941Z"
              fill="white"
            />
            <path
              d="M42.4386 13.1487L42.102 13.4535L41.6601 13.1619C41.6144 13.3297 41.5243 13.4746 41.3896 13.5965C41.129 13.8324 40.843 13.9254 40.5316 13.8754C40.223 13.8255 39.9225 13.6391 39.6302 13.3162L39.5285 13.1909L39.4509 13.0833C39.3372 13.0625 39.2484 13.0809 39.1847 13.1386C39.0892 13.2251 39.1515 13.39 39.3717 13.6332L39.7453 14.0458C39.9865 14.3122 40.1129 14.5667 40.1243 14.8093C40.1371 15.0533 40.0328 15.2756 39.8112 15.4761C39.5275 15.7331 39.1999 15.8136 38.8285 15.7177C38.4585 15.6233 38.0729 15.3546 37.6718 14.9115C37.365 14.5727 37.1897 14.2598 37.1459 13.9728C37.1019 13.6885 37.1864 13.45 37.3992 13.2573C37.5454 13.1249 37.7091 13.0598 37.8901 13.0618C38.0712 13.0639 38.2626 13.1343 38.4646 13.2729C38.4319 13.1786 38.4252 13.0767 38.4445 12.967C38.4652 12.856 38.5125 12.7671 38.5863 12.7002C38.679 12.6163 38.78 12.5737 38.8893 12.5722C39 12.5694 39.131 12.5918 39.2821 12.6394C39.2218 12.4622 39.2183 12.2808 39.2718 12.0954C39.3265 11.9115 39.4365 11.7448 39.6015 11.5953C39.8665 11.3554 40.1493 11.2561 40.4501 11.2973C40.7509 11.3385 41.0461 11.5191 41.3358 11.8391C41.3975 11.9071 41.4637 11.9934 41.5346 12.0978C41.6082 12.2024 41.6539 12.2704 41.6717 12.3017L42.4386 13.1487ZM37.9704 13.7835C37.8792 13.8661 37.8469 13.9744 37.8734 14.1085C37.9013 14.244 37.9866 14.3906 38.1295 14.5485C38.3445 14.7859 38.5455 14.9425 38.7326 15.0181C38.9197 15.0938 39.0697 15.0805 39.1826 14.9782C39.2738 14.8956 39.3007 14.7988 39.2634 14.6877C39.226 14.5766 39.132 14.4378 38.9812 14.2713L38.6706 13.9282C38.5604 13.8066 38.4398 13.73 38.3086 13.6986C38.1773 13.67 38.0646 13.6983 37.9704 13.7835ZM40.165 12.2352C40.0333 12.3545 39.9559 12.4785 39.9329 12.6074C39.9112 12.7377 39.9561 12.8643 40.0675 12.9874C40.1803 13.1119 40.3012 13.1684 40.4304 13.1568C40.5596 13.1453 40.69 13.0799 40.8218 12.9606C41.1142 12.6958 41.1517 12.4432 40.9341 12.2028C40.7138 11.9596 40.4575 11.9704 40.165 12.2352Z"
              fill="white"
            />
            <path
              d="M41.851 18.3943L41.3952 17.6257L43.0407 16.6499C43.4472 16.4088 43.5609 16.137 43.3815 15.8347C43.254 15.6196 43.0888 15.5076 42.8858 15.4985C42.6829 15.4894 42.4176 15.582 42.09 15.7763L40.7646 16.5623L40.3088 15.7937L44.2298 13.4685L44.6856 14.2371L43.8867 14.7108C43.8246 14.7476 43.6749 14.8284 43.4378 14.9532L43.2006 15.07L43.2245 15.1103C43.6016 15.1183 43.9007 15.3088 44.1219 15.6817C44.3182 16.0127 44.3781 16.3166 44.3016 16.5936C44.2252 16.8705 44.0206 17.1076 43.688 17.3049L41.851 18.3943Z"
              fill="white"
            />
            <path
              d="M43.2737 20.3453C43.3269 20.4922 43.4228 20.6568 43.5616 20.8393L42.9362 21.0656C42.7668 20.8902 42.6269 20.65 42.5165 20.3452C42.3949 20.0091 42.3906 19.7333 42.5037 19.5179C42.6193 19.3036 42.847 19.135 43.1868 19.012L44.6716 18.4745L44.526 18.0723L44.8814 17.9437L45.3299 18.3048L46.0677 18.3119L46.2622 18.849L45.6066 19.0864L45.9187 19.9486L45.2879 20.177L44.9757 19.3147L43.4909 19.8522C43.3715 19.8954 43.2954 19.9604 43.2624 20.0471C43.23 20.1357 43.2338 20.2351 43.2737 20.3453Z"
              fill="white"
            />
            <path
              d="M44.4042 23.7739C44.075 23.841 43.8009 23.7774 43.5818 23.5829C43.3631 23.3903 43.2072 23.0663 43.1143 22.6108C43.0667 22.3774 43.0423 22.175 43.0412 22.0038C43.0383 21.833 43.0528 21.6696 43.0847 21.5135L43.8081 21.3659C43.7647 21.5442 43.7379 21.74 43.7277 21.9534C43.7178 22.1687 43.7286 22.3539 43.7603 22.5089C43.8251 22.8266 43.9494 22.9667 44.1331 22.9292C44.202 22.9151 44.2532 22.8827 44.2867 22.832C44.3221 22.781 44.3561 22.6983 44.3886 22.584C44.423 22.4693 44.459 22.3185 44.4965 22.1314C44.5495 21.8634 44.6081 21.6621 44.6723 21.5274C44.7369 21.3946 44.8202 21.2899 44.9221 21.2133C45.0264 21.1382 45.1618 21.0837 45.3283 21.0497C45.6134 20.9915 45.856 21.0566 46.0559 21.2451C46.2581 21.435 46.4006 21.7329 46.4834 22.1386C46.5623 22.5251 46.5548 22.9183 46.461 23.3182L45.7756 23.183C45.8117 23.0082 45.8375 22.8465 45.853 22.6978C45.8685 22.5491 45.8614 22.402 45.8317 22.2566C45.779 21.9982 45.6828 21.8833 45.5431 21.9118C45.4646 21.9279 45.4051 21.9829 45.3645 22.0769C45.3243 22.1728 45.2764 22.3729 45.2209 22.6773C45.1706 22.9487 45.1134 23.1517 45.0492 23.2864C44.985 23.4211 44.9011 23.5279 44.7977 23.6069C44.6942 23.6858 44.563 23.7415 44.4042 23.7739Z"
              fill="white"
            />
          </>
        )}
        <path
          style={{ animationDuration: Time, ...style }}
          className={`${className} ${id === play ? "start" : "pause"}`}
          id="startPlaying"
          d="M 26 0.500008 C 31.0434 0.500008 35.9736 1.99558 40.1671 4.79755 C 44.3605 7.59953 47.6289 11.5821 49.5589 16.2416 C 51.4889 20.9011 51.9939 26.0283 51.01 30.9748 C 50.0261 35.9213 47.5974 40.465 44.0312 44.0313 C 40.465 47.5975 35.9213 50.0261 30.9748 51.01 C 26.0283 51.9939 20.9011 51.4889 16.2416 49.5589 C 11.5821 47.6289 7.59953 44.3605 4.79755 40.1671 C 1.99558 35.9736 0.500008 31.0434 0.500008 26 C 0.500008 19.237 3.18658 12.7509 7.96876 7.96876 C 12.7509 3.18658 19.237 0.500008 26 0.500008 Z"
          stroke="white"
          strokeWidth="3"
        />
      </svg>
    </>
  );
};

export default AudioIcon;
