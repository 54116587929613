import React, { useContext, useState } from "react";
import style from "./style.module.css";
import { IntrectiveContex } from "../Intrective_Wraper";
import {
  DavidImg,
  DavidVideoBG1,
  DoctorDavid1,
  DoctorMary,
  MaryImg,
  MaryVideoBG,
} from "../../../Constants/InteractiveVideoConstants";
import Loader from "../../Loader/Loader";

export const patients = [
  {
    id: 0,
    name: "Mary",
    img: MaryImg,
    poster: MaryVideoBG,
    url: DoctorMary,
  },
  {
    id: 1,
    name: "David",
    img: DavidImg,
    poster: DavidVideoBG1,
    url: DoctorDavid1,
  },
];
const PatientsSection = () => {
  const { setSlide, setData, setCheckVideo } = useContext(IntrectiveContex);
  const [loader, setLoader] = useState(true);
  function handlePatientSelection(id) {
    setSlide((prev) => prev + 1);
    setData(patients[id]);
    setCheckVideo(true);
  }
  return (
    <>
      <Loader
        styles={{
          backgroundColor: "rgba(0,0,0,0.8)",
          zIndex: loader ? 10 : -1,
        }}
      />
      <section className={style.wrapper}>
        <div className={style.insight}>Insight</div>
        <h1 className={style.heading}>
          Unconscious bias exist in clinical trial, especially towards
          <br />
          under-represented patient population
        </h1>
        <h4 className={style.subHeading}>
          We will present you with a story to observe and reflect on these
          unconscious biases.
        </h4>
        <div className={style.container}>
          <h4 className={style.subHeading}>
            Mary and David are both having zoom appointments with Dr. Smith this
            month. Choose one of them to start exploring their experiences and
            look out for the differences.
          </h4>
          <div className={style.patientContainer}>
            {patients.map((patient) => (
              <div key={patient.id} className={style.patientImg}>
                <img
                  onLoad={() => setLoader(false)}
                  src={patient.img}
                  alt={patient.name}
                  height="100%"
                  width="100%"
                />
                <div
                  className={style.cta}
                  onClick={() => handlePatientSelection(patient.id)}
                >
                  {patient.name}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default PatientsSection;
