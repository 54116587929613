import style from "./Loader.module.css";
const Loader = ({ styles }) => {
  return (
    <div className={style.container} style={{ ...styles }}>
      <div className={style.loadingio}>
        <div className={style.loader}>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
