import React, { useContext, useEffect, useState, useRef } from "react";
import { IntrectiveContex } from "../Intrective_Wraper";
import style from "./style.module.css";
import PatientsVideo from "../PatientsVideo";
import { patients } from "../PatientsSection";
import { details } from "../Details";
import {
  calLogo,
  playLogo,
} from "../../../Constants/InteractiveVideoConstants";
import Loader from "../../Loader/Loader";

const PatientSelected = () => {
  const wrapperRef = useRef();
  const { slide, setSlide, data, setData, checkVideo } =
    useContext(IntrectiveContex);
  const [playVideo, setPlayVideo] = useState(true);
  const [newData, setNewData] = useState();
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (checkVideo) {
      setData((prev) => patients.filter((item) => item.id === prev.id)[0]);
      setNewData(patients.filter((item) => item.id !== data.id)[0]);
    } else {
      setNewData(details);
      setPlayVideo(true);
    }
  }, [checkVideo]);

  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.style.display = "flex";
      wrapperRef.current.style.opacity = 1;
    }
  }, [data]);

  const handleBtn = () => {
    if (playVideo) {
      wrapperRef.current.style.opacity = 0;
    } else {
      wrapperRef.current.style.display = "flex";
      wrapperRef.current.style.opacity = 1;
      setPlayVideo(true);
    }
    setTimeout(() => {
      wrapperRef.current.style.display = "none";
      setPlayVideo(false);
    }, 1000);
  };

  return (
    <>
      <Loader
        styles={{
          backgroundColor: "rgba(0,0,0,0.8)",
          zIndex: loader ? 10 : -1,
        }}
      />
      <section className={style.wrapper} onLoad={() => setLoader(false)}>
        <div
          className={style.patientsWrapper}
          style={{ zIndex: !playVideo ? 0 : 1 }}
          ref={wrapperRef}
        >
          <div className={style.patients}>
            {data.id === 0 ? (
              <h1>
                {`Monday afternoon at 1 pm, ${data.name} has a Zoom appointment with Dr. Smith. Let’s see how it goes.`}
              </h1>
            ) : (
              <h1>
                {`Monday afternoon at 5 pm, ${data.name} has a Zoom appointment with Dr. Smith. Let’s see what happens.`}
              </h1>
            )}
            <button className={style.playButton} onClick={handleBtn}>
              <img src={playLogo} alt="play video by clicking on it" />
            </button>
            <img src={calLogo} className={style.calLogo} alt="" />
          </div>
        </div>
        <PatientsVideo playVideo={playVideo} newData={newData} />
      </section>
    </>
  );
};

export default PatientSelected;
