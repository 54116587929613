import React, { useContext, useState } from "react";
import style from "../../Assets/css/global.module.css";
import Circle from "../Circle";
import { SlideContext } from "../RenderSlides";
import enroll from "../../Assets/Images/slides/enroll.jpg";
import "./style.css";
import Loader from "../Loader/Loader";
const Enrollment = () => {
  const { setSlide, close } = useContext(SlideContext);
  const [loader, setLoader] = useState(true);

  const handleTimeClick = () => {
    setSlide(1);
  };
  const handleCriticalLevelClick = () => {
    setSlide(2);
  };
  return (
    <>
      <Loader
        styles={{
          backgroundColor: "rgba(255,255,255,1)",
          zIndex: loader ? 100 : -1,
        }}
      />
      <section className={style.wrapper}>
        <div className={style.wrapper2}>
          <img
            className={style.svg}
            src={enroll}
            alt=""
            onLoad={() => setLoader(false)}
          />

          <div className={` ${style.positionedCircle1} pulse `}>
            <Circle onAction={handleTimeClick} />
          </div>
          <div className={` ${style.positionedCircle2} pulse `}>
            <Circle onAction={handleCriticalLevelClick} />
          </div>
        </div>
      </section>
    </>
  );
};

export default Enrollment;
