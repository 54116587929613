import React, { useContext } from "react";
import { useState } from "react";
import style from "../../Assets/css/global.module.css";
// import banner from "../../Assets/Images/slides/banner.jpg";
// import office2 from "../../Assets/Images/slides/office2.png";
import CloseIcon from "../../Assets/svg/CloseIcon.js";
import Loader from "../Loader/Loader";
import { SlideContext } from "../RenderSlides";
import "./style.css";
function EmailSlide({ img, button, banner }) {
  const { setSlide, close } = useContext(SlideContext);
  const [loader, setLoader] = useState(true);

  return (
    <>
      <Loader
        styles={{
          backgroundColor: "rgba(255,255,255,1)",
          zIndex: loader ? 100 : -1,
        }}
      />
      <section className={style.wrapper}>
        <section className={style.wrapper2}>
          <img
            className={style.svg}
            src={banner}
            alt="background"
            onLoad={() => setLoader(false)}
          />
          <div className={style.Container}>
            <img className="aboutImg2" src={img} alt="summary" />
            <button
              className={`${style.button} positionBtn2`}
              onClick={() =>
                button === "Complete" ? setSlide(2) : setSlide(5)
              }
            >
              {button}
            </button>
          </div>
          <div>
            <CloseIcon
              className="CloseViewEmailSlide"
              onAction={() => close()}
            />
          </div>
        </section>
      </section>
    </>
  );
}

export default EmailSlide;
