import React, { useContext, useState, useRef, useEffect } from "react";
import style from "./style.module.css";
import { IntrectiveContex } from "../Intrective_Wraper";
import Loader from "../../Loader/Loader";
import {
  pauseLogo,
  playLogo,
} from "../../../Constants/InteractiveVideoConstants";

const PatientsVideo = ({ playVideo, newData }) => {
  let videoRef = useRef(null);
  const [showLoader, setShowLoader] = useState(true);
  const [playing, setPlaying] = useState(true);
  const { data, setSlide, setData, setCheckVideo } =
    useContext(IntrectiveContex);

  useEffect(() => {
    if (videoRef && !playVideo) {
      videoRef.current.play();
    }
  }, [playVideo]);

  const handleVideo = () => {
    setSlide((prev) => prev + 1);
    setCheckVideo(false);
    setData(newData);
    setShowLoader(true);
  };

  const handlePlay = () => {
    setShowLoader(false);
  };

  const togglePause = (e) => {
    if (!videoRef.current.paused) {
      setPlaying(false);
      videoRef.current.pause();
    } else {
      setPlaying(true);
      videoRef.current.play();
    }
  };

  const getIcon = () => {
    return !playing ? playLogo : pauseLogo;
  };
  return (
    <section className={style.wrapper}>
      <video
        ref={videoRef}
        onPlay={handlePlay}
        onEnded={handleVideo}
        poster={data.poster}
        disablePictureInPicture
        preload="auto"
        src={data.url}
        type="video/mp4"
      />
      {!playVideo && (
        <div onClick={togglePause} className={style.togglePause}>
          <img src={getIcon()} alt="" />
        </div>
      )}
      {showLoader && !playVideo && <Loader />}
    </section>
  );
};

export default PatientsVideo;
