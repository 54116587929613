// images
import playLogo from "../Assets/Images/intrective/play.png";
import pauseLogo from "../Assets/Images/intrective/pause.png";
import lightLogo from "../Assets/Images/intrective/light.png";
import calLogo from "../Assets/Images/intrective/cal.png";
import discussLogo from "../Assets/Images/intrective/discuss.png";
import groupLogo from "../Assets/Images/intrective/group.png";
import DavidVideoBG1 from "../Assets/Images/intrective/DavidVideoBG1.png";
import DavidVideoBG from "../Assets/Images/intrective/DavidVideoBG.png";
import MaryVideoBG from "../Assets/Images/intrective/MaryVideoBG.png";
import playBtn from "../Assets/Images/intrective/playBtn.png";
import pauseBtn from "../Assets/Images/intrective/pauseBtn.png";

import MaryImg from "../Assets/Images/intrective/mary.png";
import DavidImg from "../Assets/Images/intrective/david.png";

// videos
const DoctorMary = "/assets/images/intrective/videos/Doctor&Mary.mp4";
const DoctorDavid1 = "/assets/images/intrective/videos/Doctor&David1.mp4";
const DoctorDavid2 = "/assets/images/intrective/videos/Doctor&David2.mp4";
const DoctorDavid3 = "/assets/images/intrective/videos/Doctor&David3.mp4";

export {
  playLogo,
  pauseLogo,
  lightLogo,
  calLogo,
  playBtn,
  pauseBtn,
  discussLogo,
  groupLogo,
  MaryImg,
  DavidImg,
  DavidVideoBG1,
  DavidVideoBG,
  MaryVideoBG,
  DoctorMary,
  DoctorDavid1,
  DoctorDavid2,
  DoctorDavid3,
};
