import React, { createContext, useState } from "react";
import EmailSlide from "../EmailSlide";
import Enrollment from "../Enrollment";
import HcpOffice from "../HcpOffice";
import Office from "../Office";
import PatientsHome from "../PatientsHome";
import ViewEmail from "../ViewEmail";
import office2 from "../../Assets/Images/slides/office2.png";
import office3 from "../../Assets/Images/slides/office3.png";
import banner2 from "../../Assets/Images/slides/banner4.jpg";
import banner from "../../Assets/Images/slides/banner.jpg";

export const SlideContext = createContext(null);

let SlideIndexForHotspot = [1, 2];

function RenderSlides({ data, handleClose }) {
  let initalSlide =
    data.initalSlide != undefined || data.initalSlide != null
      ? SlideIndexForHotspot[data.initalSlide]
      : 0;
  const [slide, setSlide] = useState(initalSlide);
  return (
    <SlideContext.Provider value={{ setSlide, close: handleClose }}>
      <SwitchComponents slide={slide} />
      {/* <Enrollment /> */}
    </SlideContext.Provider>
  );
}

function SwitchComponents({ slide }) {
  switch (slide) {
    case 0:
      return <Enrollment />;
    case 1:
      return <EmailSlide banner={banner} img={office2} button="Start" />;
    case 2:
      return <Office />;
    case 3:
      return <HcpOffice />;
    case 4:
      return <PatientsHome />;
    case 5:
      return <ViewEmail />;
    case 6:
      return <EmailSlide banner={banner2} img={office3} button="Complete" />;
    default:
      return null;
  }
}
export default RenderSlides;
// export default SwitchComponents
