import React, { useContext, useEffect, useRef, useState } from "react";
import { IntrectiveContex } from "../Intrective_Wraper";
import style from "./style.module.css";
import PatientsVideo from "../PatientsVideo";
import {
  DavidVideoBG,
  discussLogo,
  DoctorDavid3,
  groupLogo,
  playLogo,
} from "../../../Constants/InteractiveVideoConstants";
import Loader from "../../Loader/Loader";

const details = [
  {
    id: 0,
    about: "Trying again does work!",
    style: { color: "#8F5AFF", fontWeight: 700 },
  },
  {
    id: 1,
    about:
      "One of the top barriers to participation in clinical trials is an overall discomfort with the idea of a trial—a lack of trust in trials and medical research. Using specific language conveys respect for the patient, which in turn builds trust. Even after initially rejecting, many patients are still open to participating in a trial after being asked again if patient-centered, appropriately detailed language is used.",
    style: {},
  },
  {
    id: 2,
    about: `How can we improve clinical trials by addressing these biases?​ \nTurn to your left-hand side to find out more.`,
    style: {},
  },
];
const PatientChoice = () => {
  const { slide, setSlide, data, setData, checkVideo, setCheckVideo } =
    useContext(IntrectiveContex);
  const wrapperRef = useRef();

  const [newData, setNewData] = useState(details);
  const [noBtn, setNoBtn] = useState(false);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.style.display = "flex";
      wrapperRef.current.style.opacity = 1;
    }
    setData({
      url: DoctorDavid3,
      poster: DavidVideoBG,
    });
  }, [newData]);

  const handleVideoURL = (url) => {
    if (!checkVideo) {
      wrapperRef.current.style.opacity = 0;
    } else {
      wrapperRef.current.style.display = "flex";
      wrapperRef.current.style.opacity = 1;
    }
    setTimeout(() => {
      wrapperRef.current.style.display = "none";
      setCheckVideo(true);
    }, 1000);
  };

  return (
    <>
      <Loader
        styles={{
          backgroundColor: "rgba(0,0,0,0.8)",
          zIndex: loader ? 10 : -1,
        }}
      />
      <section className={style.wrapper} onLoad={() => setLoader(false)}>
        <div
          className={style.patientsWrapper}
          style={{ zIndex: !checkVideo ? 1 : 0 }}
          ref={wrapperRef}
        >
          <div className={style.patients}>
            <h1
              style={{
                paddingTop: noBtn ? "3rem" : "",
                paddingBottom: noBtn ? "2rem" : "",
              }}
            >
              {!noBtn
                ? "If you were Dr. Smith, would you try asking David to participate in the clinical trial again?"
                : "During the appointment, Dr. Smith did not mention the clinical trial and David lost the chance to participate. Let’s see what happens if Dr. Smith tried again."}
            </h1>
            <div className={style.btnSection}>
              {!noBtn ? (
                <>
                  <button
                    className={style.playButton}
                    onClick={() => handleVideoURL(DoctorDavid3)}
                  >
                    Yes
                  </button>
                  <button
                    className={style.playButton}
                    onClick={() => setNoBtn(true)}
                  >
                    No
                  </button>
                </>
              ) : (
                <button
                  className={style.playButton}
                  onClick={() => handleVideoURL(DoctorDavid3)}
                >
                  <img src={playLogo} alt="play video by clicking on it" />
                </button>
              )}
            </div>
            <img
              src={!noBtn ? discussLogo : groupLogo}
              className={style.discussLogo}
              alt=""
            />
          </div>
        </div>
        <PatientsVideo playVideo={!checkVideo} newData={newData} />
      </section>
    </>
  );
};

export default PatientChoice;
