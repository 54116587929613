import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Forms } from "../Components/NegotiationFormComponents/Forms";
import {
  formsData,
  formsData1,
  formsData2,
  formsData3,
} from "../Constants/NegotiationFormData/FormsData";
import {
  ContentSubType,
  ContentType,
} from "../Containers/MediaModal/MediaModal";
import { firestore } from "../Firebase";
import { NotificationManager } from "../Managers/NotificationManager";
import { UserContext } from "./Auth/UserContextProvider";
import { MediaModalContext } from "./MediaModal/MediaModalContextProvider";
import Specs_BoothActivity from "../Components/RenderPerspective";
import Intrective_Wrapper from "../Components/Video_Intrective/Intrective_Wraper";
import RenderSlides from "../Components/RenderSlides";

export const UIContext = createContext(null);

export const MenuStates = {
  participants: 1,
  publicChat: 2,
  polls: 3,
  notification: 4,
};

export const DEFAULT_ROOM_NAME = "call-room-test";

export const SCENE_DATA = {
  avatar: {
    id: "avatar",
    src: "/avatar/index.html",
  },
  lobby: {
    id: "lobby",
    src: "/3dScene/index.html",
  },
  library: {
    id: "library",
    src: "/library/index.html",
  },
  tunnel: {
    id: "tunnel",
    src: "/tunnel/index.html",
  },
  rdeShowcase: {
    id: "rdeShowcase",
    src: "/rdeShowcase/index.html",
  },
};

export const PossibleFormData = {
  "form-1": {
    formid: "form-1",
    formsData: formsData1,
  },
  "form-2": {
    formid: "form-2",
    formsData: formsData2,
  },
  "form-3": {
    formid: "form-3",
    formsData: formsData3,
  },
};

var BoothAcitvity = {
  DEFAULT: 0,
  GLASSES: 1,
  GAME: 2,
  HOTSPOTS_MODULE: 3,
  UNKNOWN: 4,
  INTERACTIVE_VIDEO: 5,
};

var ModeOfTeleportation = {
  SIDE_MENU: 0,
  BEAMSPOT: 1,
  TUNNEL: 2,
  BOOTH_GATE: 3,
  ON_CLICK: 4,
};

export const PlayerState = {
  currentRoomArea: 5,
  modeOfTeleportation: ModeOfTeleportation.SIDE_MENU,
  lastRoomArea: 0,
};

export const UIContextProvider = (props) => {
  const [isMenuOpen, toggleMenuOpen] = useState(null); //MenuStates.participants
  const [activeMenu, setActiveMenu] = useState(null); //MenuStates.participants
  const [videocall, setVideocall] = useState(null);
  const [activeForm, setActiveForm] = useState(null);
  const [currentScene, setCurrentScene] = useState(SCENE_DATA.rdeShowcase);
  const [playerState, setPlayerState] = useState(PlayerState);
  const userColorClassRecord = useRef(
    localStorage.getItem("participantColor")
      ? JSON.parse(localStorage.getItem("participantColor"))
      : {}
  );

  const { showMediaModal, closeMediaModal, mediaModalStatus, modalDetails } =
    useContext(MediaModalContext);

  useMemo(() => {
    if (!localStorage.getItem("returingUser")) {
      window.localStorage.setItem("returingUser", "true");
      window.localStorage.setItem("tutorialDone", "false");
      window.localStorage.setItem("welcomeVO", "true");
    }
    if (localStorage.getItem("avatarDone") === "true") {
      setCurrentScene(SCENE_DATA.rdeShowcase);
    }
  }, []);

  useEffect(() => {
    window.parent.GetPlayerRoom = function () {
      console.log(playerState);
      return playerState.currentRoomArea;
    };
    window.parent.GetPlayerState = function () {
      return playerState;
    };
  }, [playerState]);

  useEffect(() => {
    window.connectToVideocallRoom = connectToVideocallRoom;
    window.parent.connectToVideocallRoom = connectToVideocallRoom;
    window.parent.switchSceneInformer = updateScene;
    window.parent.openIframe = (iName, iUrl, iType) => {
      showMediaModal({
        type: iType,
        link: iUrl,
        name: iName,
      });

      if (typeof window.parent.tutorialThreeCompleteHard !== "undefined")
        window.parent.tutorialThreeCompleteHard();
    };
    window.parent.closeMediaModal = closeMediaModal;

    //#region Player State Functions
    window.parent.UpdateUserState = function (index, mode) {
      console.log("Update user state - " + index);
      if (typeof window.parent.SpecificAudio !== "undefined") {
        window.parent.SpecificAudio("vog");
      }
      setPlayerState((prev) => {
        if (prev && prev.currentRoomArea !== index) {
          return {
            ...prev,
            currentRoomArea: index,
            lastRoomArea: prev.currentRoomArea,
            modeOfTeleportation: mode,
          };
        } else {
          return {
            ...prev,
            modeOfTeleportation: mode,
          };
        }
      });
    };

    window.parent.UpdateUserLastPose = function (pLastPoseIndex) {
      setPlayerState((prev) => {
        return { ...prev, lastPoseIndex: pLastPoseIndex };
      });
    };
    //#endregion

    window.parent.PageReload = () => {
      console.log("FUNCTION CALLED!---");
      window.location.reload();
    };

    window.parent.activateSpecs = (flag) => {
      showMediaModal({
        type: ContentType.FullComponent,
        component: Specs_BoothActivity,
        data: { flag },
      });
    };

    window.parent.playAudio = (name, className = "") => {
      const audioElement = document.createElement("audio");
      audioElement.src = name;
      audioElement.setAttribute("id", "audioElement");
      if (className !== "") {
        audioElement.classList.add(className);
      }
      audioElement.play();
      audioElement.volume = 1;
      let body = document.getElementsByTagName("body")[0];
      body.appendChild(audioElement);
    };

    window.parent.stopAudio = () => {
      const ele = document.getElementById("audioElement");
      if (ele) {
        // ele.stop();
        ele.volume = 0;
        ele.remove();
      }
    };

    window.parent.SpecificAudio = (className) => {
      const eles = document.querySelectorAll("." + className);
      console.log("ELEMENTS - ");
      console.log(eles);
      eles.forEach((ele) => {
        ele.volume = 0;
        ele.remove();
      });
    };

    window.parent.openActivity = (activityIndex, activityConfig) => {
      const { subIndex, callback } = activityConfig;
      switch (activityIndex) {
        case BoothAcitvity.GAME:
          window.parent.ToggleTimeScale(0);
          showMediaModal(
            {
              type: ContentType.Iframe,
              link: "/TrailGame/index.html",
              contentSubType: ContentSubType.lit,
            },
            () => {
              window.parent.ToggleTimeScale(1);
              callback();
            }
          );
          console.log("Activity for game module called!");
          break;
        case BoothAcitvity.GLASSES:
          showMediaModal(
            {
              type: ContentType.FullComponent,
              component: Specs_BoothActivity,
              data: { flagIndex: subIndex },
            },
            () => callback()
          );
          break;
        case BoothAcitvity.INTERACTIVE_VIDEO:
          showMediaModal(
            {
              type: ContentType.FullComponent,
              component: Intrective_Wrapper,
              data: { initalSelection: subIndex },
            },
            () => callback()
          );
          break;
        case BoothAcitvity.HOTSPOTS_MODULE:
          showMediaModal(
            {
              type: ContentType.FullComponent,
              component: RenderSlides,
              data: { initalSlide: subIndex },
            },
            () => callback()
          );
          console.log("Activity for hotstpot module called!");
          break;
        default:
          console.error("please use vaild activityIndex");
      }
    };

    window.parent.activateForm = (formid) => {
      if (PossibleFormData.hasOwnProperty(formid)) {
        setActiveForm(PossibleFormData[formid]);
        showMediaModal({
          type: ContentType.Component,
          component: Forms,
          data: PossibleFormData[formid],
        });
      }
    };
    window.parent.closeForm = (formid) => {
      setActiveForm(null);
    };

    // window.parent.openActivity = (index,data) => {
    //     console.log("Called open activity!");
    //     setTimeout( () => {
    //         data.callback();
    //     },3000);
    // }

    // window.parent.activateForm('form-2')
  }, []);

  const getParticipantColorNumber = (id) => {
    // console.log(id)
    let mainObject = userColorClassRecord.current;
    if (!mainObject.hasOwnProperty(id)) {
      let val = Math.floor(Math.random() * 3);
      mainObject[id] = val;
      localStorage.setItem(
        "participantColor",
        JSON.stringify(userColorClassRecord.current)
      );
    }
    return mainObject[id];
  };

  const connectToVideocallRoom = (
    roomId = null,
    temporaryRoom = false,
    sideLayout = false
  ) => {
    console.log(roomId, temporaryRoom, sideLayout);
    if (!roomId) {
      // setActiveMenu(MenuStates.participants)
    } else {
      if (typeof window.parent.connectToVideocallRoomInformer != "undefined")
        window.parent.connectToVideocallRoomInformer();
    }
    toggleMenuOpen(null);
    if (roomId) {
      if (sideLayout) {
        if (window.parent.closePrivateChatRoom)
          window.parent.closePrivateChatRoom();
        setActiveMenu(MenuStates.participants);
      }
      setVideocall({
        roomId: roomId, //"test-ilex-room-1",//roomId,
        temporaryRoom, //temporaryRoom ? 'true' : 'false',
        sideLayout,
      });
      if (!sideLayout)
        if (typeof window.parent.hideInLobby != "undefined")
          window.parent.hideInLobby();
    } else {
      setVideocall(null);
      if (typeof window.parent.showInLobby != "undefined")
        window.parent.showInLobby();
    }
  };

  const closeVideocallRoom = () => {
    connectToVideocallRoom();
  };

  const updateScene = (sceneId) => {
    if (SCENE_DATA.hasOwnProperty(sceneId)) {
      console.log(sceneId);
      // if (sceneId == "library") {
      //     if (typeof window.parent.hideInLobby != "undefined")
      //         window.parent.hideInLobby();
      // } else if (sceneId == "lobby") {
      //     if (typeof window.parent.showInLobby != "undefined")
      //         window.parent.showInLobby();
      // }else{
      //     if (typeof window.parent.hideInLobby != "undefined")
      //     window.parent.hideInLobby();
      // }

      if (typeof window.parent.tutorialThreeCompleteHard !== "undefined")
        window.parent.tutorialThreeCompleteHard();
      console.log("switching scene to " + sceneId);
      // setTimeout(() => {
      setCurrentScene(SCENE_DATA[sceneId]);
      // }, 500);
    } else {
      console.error("No such scene exists: " + sceneId);
    }
  };

  const lastVal = useRef(MenuStates.participants);
  const toggleSideMenu = () => {
    // console.log("toggle"
    toggleMenuOpen((prev) => !prev);
    setActiveMenu((prev) => {
      // console.log(prev)
      if (prev === 0 || prev) {
        lastVal.current = prev;
        // console.log("set null")
        return prev;
      } else {
        // console.log("set prev")
        return lastVal.current ? lastVal.current : MenuStates.participants;
      }
    });
  };

  const openSideMenu = (val = true) => {
    toggleMenuOpen(val);
    setActiveMenu((prev) => {
      // console.log(prev)
      if (prev === 0 || prev) {
        lastVal.current = prev;
        // console.log("set null")
        return prev;
      } else {
        // console.log("set prev")
        return lastVal.current ? lastVal.current : MenuStates.participants;
      }
    });
  };

  return (
    <UIContext.Provider
      value={{
        isMenuOpen,
        toggleMenuOpen,
        openSideMenu,
        activeMenu,
        setActiveMenu,
        getParticipantColorNumber,
        videocall,
        connectToVideocallRoom,
        updateScene,
        currentScene,
        closeVideocallRoom,
        toggleSideMenu,
      }}
    >
      {props.children}
    </UIContext.Provider>
  );
};
