import React, { useEffect, useState, useRef } from "react";
import "./style.css";

const TunelVideo = () => {
  let videoRef = useRef(null);
  let loadingPercentatgeRef = useRef(null);
  const [toPlayTunelVideo, setTunelVideoStatus] = useState(false);

  useEffect(() => {
    window.parent.PlayTunelVideo = () => {
      setTunelVideoStatus(true);
    };

    window.parent.StopTunelVideo = () => {
      if (videoRef.current) videoRef.current.classList.add("fadeOut");
      if (loadingPercentatgeRef.current) {
        loadingPercentatgeRef.current.classList.add("fadeOut");
        setTimeout(() => {
          setTunelVideoStatus(false);
        }, 1000);
        loadingPercentatgeRef.current.innerHTML =
          Math.floor(0 * 100).toString() + "%";
      }
    };

    window.parent.UpatePercentage = (value) => {
      if (value > 0.1)
        if (loadingPercentatgeRef.current)
          loadingPercentatgeRef.current.innerHTML =
            Math.floor(value * 100).toString() + "%";
    };
    // setTunelVideoStatus(true);
  }, []);

  if (toPlayTunelVideo) {
    return (
      <>
        <video
          ref={videoRef}
          src="/assets/video/beamspot.mp4"
          autoPlay
          loop={true}
          className="TunelVideoPlayer"
        ></video>
        <h1 className="TunelPercentage" ref={loadingPercentatgeRef}>
          0%
        </h1>
      </>
    );
  } else {
    return false;
  }
};

export default TunelVideo;
