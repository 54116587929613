import React, { useMemo, useEffect, useState, useContext } from "react";
import { SlideContext } from "../RenderSlides";
// import audio from "../../Assets/audio/audio.mp3";
export const useAudioHook = (audio) => {
  const [play, setPlay] = useState(null);
  const [audioTime, setAudioTime] = useState();
  const [audioEnd, setAudioEnd] = useState(false)
  const { setSlide, close } = useContext(SlideContext);
  const audioFile = useMemo(() => {
    return new Audio(audio);
  }, [audio]);
  useEffect(() => {
    return () => {
      audioFile.pause();
    };
  }, [audioFile]);

  // function changeSlide(id) {
  //   if (id===1) {
  //     setSlide(4)
  //   }
  // }

  function handleAudio(id) {
    setPlay((prv) => (prv === id ? null : id));
    // if (audioEnd) {
    //   setSlide(4)
    // }
    console.log(id)
    if (audioFile.paused) {
      audioFile.play();
      setAudioTime(audioFile.duration);
      console.log(audioFile.duration);
      console.log(audioFile.paused);
    } else {
      audioFile.pause();
      console.log(audioFile.currentTime);
    }
  }
  audioFile.addEventListener("ended", () => {
    setPlay(null);
    setAudioEnd(true)
    audioFile.currentTime = 0;
  });
  audioFile.addEventListener("progress", (e) => {
    if (!e) {
      setPlay(null);
      console.log("current");
    } else {
      console.log("loaded");
    }
    // audioFile.currentTime = 0;
  });
  return {
    handleAudio,
    audioTime,
    play,
    audioEnd,
    // changeSlide
  };
};
