import React, { useContext, useRef, useState } from "react";
import { IntrectiveContex } from "../Intrective_Wraper";
import style from "./style.module.css";
import PatientsVideo from "../PatientsVideo";
import {
  DavidVideoBG,
  DoctorDavid2,
  lightLogo,
  playLogo,
} from "../../../Constants/InteractiveVideoConstants";
import Loader from "../../Loader/Loader";
import { useEffect } from "react";

export const details = [
  {
    id: 0,
    about: `There is a difference between how Dr. Smith talks to Sarah and David.\nOur research has investigated the reasons behind.`,
    style: { color: "#8F5AFF", fontWeight: 700 },
  },
  {
    id: 1,
    about: `Principle investigators report that they only offer clinical trials to 44% of assumed clinically eligible patients—however, 75% of patients will consider participating in a trial if recommended by their doctor.\nOne reason for this discrepancy is underlying biases in the recruiting process—including perceived differences in health literacy due to race and language.\nThe doctor used descriptive language when speaking with the first patient, which showed her that it was both scientifically sound and personally beneficial for the patient. But when the doctor spoke with the second patient, he used more general language because he may have made assumptions about the patient and assumed a lower level of health literacy.`,
    style: {},
  },
  {
    id: 2,
    about: `After learning about the best practice, Dr Smith is \nexpecting another visit from David. Let’s see what \nhappens this time.`,
    style: {},
  },
];

const Details = () => {
  const wrapperRef = useRef();

  const {
    checkVideo,
    setCheckVideo,
    setData,
    data,
    handleClose,
    setShowClose,
  } = useContext(IntrectiveContex);

  const [nextBtn, setNextBtn] = useState(0);
  const [loader, setLoader] = useState(true);
  const [newData, setNewData] = useState(data);

  function handleNext() {
    if (newData.length - 1 > nextBtn) {
      nextBtn === 2 &&
        newData[2].about.includes("How can we improve") &&
        setShowClose(false);
      setNextBtn((prev) => prev + 1);
    } else {
      if (newData.length > 2) {
        handleBtn();
      }
    }
  }

  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.style.display = "flex";
      wrapperRef.current.style.opacity = 1;
    }
    setData({
      url: DoctorDavid2,
      poster: DavidVideoBG,
    });
  }, [newData]);

  const handleBtn = () => {
    if (!checkVideo) {
      wrapperRef.current.style.opacity = 0;
    } else {
      wrapperRef.current.style.display = "flex";
      wrapperRef.current.style.opacity = 1;
    }
    setTimeout(() => {
      wrapperRef.current.style.display = "none";
      setCheckVideo(true);
    }, 1000);
  };

  return (
    <>
      <Loader
        styles={{
          backgroundColor: "rgba(0,0,0,0.8)",
          zIndex: loader ? 10 : -1,
        }}
      />
      <section className={style.wrapper} onLoad={() => setLoader(false)}>
        <div
          className={style.patientsWrapper}
          style={{ zIndex: !checkVideo ? 1 : 0 }}
          ref={wrapperRef}
        >
          <div
            className={style.patients}
            style={{
              height: nextBtn === 2 ? "39rem" : "",
              width: nextBtn === 2 ? "88rem" : "",
            }}
          >
            {newData.map((item) => (
              <h1
                key={item.id}
                style={{
                  color: item.style ? item.style.color : "",
                  textAlign:
                    item.id === 0 ||
                    (item.id === 2 &&
                      newData[2].about.includes("How can we improve"))
                      ? "center"
                      : "left",
                  fontSize:
                    item.id === 0 ||
                    (item.id === 2 &&
                      !newData[2].about.includes("How can we improve"))
                      ? "2.8rem"
                      : "",
                }}
              >
                {item.id === nextBtn &&
                  item.about.split("\n").map((text) => (
                    <span key={text}>
                      {text}
                      <br />
                      {item.id === 1 && <br />}
                      {newData[2].about.includes("How can we improve") && (
                        <br />
                      )}
                    </span>
                  ))}
              </h1>
            ))}
            <button
              className={style.playButton}
              onClick={() =>
                nextBtn === 2 && newData[2].about.includes("How can we improve")
                  ? handleClose()
                  : handleNext()
              }
            >
              {nextBtn < newData.length - 1 || newData.length === 3 ? (
                nextBtn === 2 &&
                newData[2].about.includes("How can we improve") ? (
                  "Complete"
                ) : nextBtn === 2 &&
                  newData[2].about.includes(
                    "After learning about the best practice"
                  ) ? (
                  <img src={playLogo} alt="play video by clicking on it" />
                ) : (
                  "Next"
                )
              ) : (
                <img src={playLogo} alt="play video by clicking on it" />
              )}
              {console.log("SD", nextBtn)}
            </button>
            <img src={lightLogo} className={style.lightLogo} alt="" />
          </div>
        </div>
        <PatientsVideo playVideo={!checkVideo} newData={newData} />
      </section>
    </>
  );
};

export default Details;
