import React, { useContext, useState } from "react";
import style from "../../Assets/css/global.module.css";
import banner from "../../Assets/Images/slides/viewEmailBanner.png";
import "./style.css";
import CloseIcon from "../../Assets/svg/CloseIcon.js";
import { SlideContext } from "../RenderSlides";
import AudioIcon from "../../Assets/svg/AudioIcon";
import audio from "../../Assets/audio/Audio/1. Email Reflection.wav";
import { useAudioHook } from "../useAudioHook";
import email2 from "../../Assets/Images/email1.png";
import email1 from "../../Assets/Images/email2.png";
import closeMail from "../../Assets/Images/slides/closeEmail.png";
import emailCloud from "../../Assets/Images/emailCloud.png";
import Loader from "../Loader/Loader";
function ViewEmail() {
  const { setSlide, close } = useContext(SlideContext);
  const [openMail, setMail] = useState(null);
  // const [play, setPlay] = useState(false);
  // const [audioTime,setAudioTime]=useState();
  const [isLoader, setIsLoader] = useState(false);
  const [loader, setLoader] = useState(true);
  const { handleAudio, audioTime, play } = useAudioHook(audio);
  const handleOpenEmail = (mail) => {
    setMail(mail);
    var newImg = new Image();
    newImg.onload = function () {
      console.log("loaded");
      setIsLoader(true);
    };
    newImg.src = mail;
  };

  return (
    <>
      <Loader
        styles={{
          backgroundColor: "rgba(255,255,255,1)",
          zIndex: loader ? 100 : -1,
        }}
      />
      <section className={style.wrapper}>
        <div className={style.wrapper2}>
          <img
            className={style.svg}
            src={banner}
            alt="banner"
            onLoad={() => setLoader(false)}
          />
          {openMail && (
            <div className="mailWrapper">
              {isLoader ? (
                <div className="mailContent">
                  <img className="mail" src={openMail} alt="email" />
                  <img
                    className="closeMail"
                    src={closeMail}
                    alt="close email"
                    onClick={() => handleOpenEmail(null)}
                  />
                </div>
              ) : (
                <div className="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              )}
            </div>
          )}
          <button
            className={`${style.button} firstBtn  positionedBtn`}
            onClick={() => handleOpenEmail(email1)}
          >
            View
          </button>
          <button
            className={`${style.button} positionedBtn nextBTn`}
            onClick={() => handleOpenEmail(email2)}
          >
            View
          </button>
          <div className="bubbleCloud">
            <div className="bubbleText">
              <img className="emailCloud" src={emailCloud} alt="conversation" />
            </div>
            <div onClick={() => handleAudio(0)}>
              <AudioIcon
                className={`audio playAudio ${audioTime && "player "}`}
                Time={`${audioTime}s`}
                play={play}
                id={0}
              />
            </div>
          </div>
          <div>
            <CloseIcon
              className="CloseViewEmailSlide"
              onAction={() => close()}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default ViewEmail;
