import React, { createContext, useState } from "react";
import Details from "../Details";
import PatientChoice from "../PatientsChoice";
import PatientSelected from "../PatientSelected";
import PatientsSection, { patients } from "../PatientsSection";
import style from "./style.module.css";

export const IntrectiveContex = createContext();
const Intrective_Wrapper = ({ handleClose: __handleClose, data: __data }) => {
  let initalSelection = __data.initalSelection !== null || __data.initalSelection !== undefined ? __data.initalSelection : -1;
  initalSelection = Math.min(Math.max(initalSelection, -1), 1);
  const [slide, setSlide] = useState(initalSelection !== -1 ? 1 : 0);
  const [showClose, setShowClose] = useState(true);
  const [data, setData] = useState(initalSelection !== -1 ? patients[initalSelection] : null);
  const [checkVideo, setCheckVideo] = useState(initalSelection !== -1);
  function handleClose() {
    setSlide(0);
    setShowClose(true);
    setData(null);
    __handleClose();
  }
  return (
    <IntrectiveContex.Provider
      value={{
        slide,
        setSlide,
        data,
        setData,
        checkVideo,
        setCheckVideo,
        setShowClose,
        handleClose: __handleClose,
      }}
    >
      <section className={style.wrapper}>
        <SwitchComponents slide={slide} />
        {showClose && (
          <div className={style.close} onClick={() => __handleClose()}>
            <svg
              style={{ marginBottom: "-1px" }}
              width="16"
              height="16"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 1L11.5 11.5" stroke="#F17922" strokeWidth="2" />
              <path d="M11.5 1L1 11.5" stroke="#F17922" strokeWidth="2" />
            </svg>{" "}
            Close
          </div>
        )}
      </section>
    </IntrectiveContex.Provider>
  );
};

function SwitchComponents({ slide }) {
  switch (slide) {
    case 0:
      return <PatientsSection />;
    case 1:
      return <PatientSelected />;
    case 2:
      return <PatientSelected />;
    case 3:
      return <Details />;
    case 4:
      return <PatientChoice />;
    case 5:
      return <Details />;
    default:
      return null;
  }
}

export default Intrective_Wrapper;
